@charset "UTF-8";
/* =================================
 Temlate Name : oitila
 Author Name : autworks
 Date: 17/01/2020
 Version: 1.0
 =================================

/* =================================
 css index
 =================================
 *. typography
 *. section title
 *. preloader
 *. scroll to top button
 *. normalize

================================= */
/*==========================================
    typography
===========================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,600,700,800,900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&amp;display=swap");
.container {
  max-width: 1200px;
}

.btn-hyipox {
  display: inline-block;
  height: 50px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  padding: 0 35px;
  color: #8e54e9;
  border-radius: 50px;
  margin-top: 40px;
  font-family: "Yantramanav", sans-serif;
  text-transform: capitalize;
}

.btn-hyipox-2 {
  display: inline-block;
  height: 50px;
  padding: 0 40px;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  color: #fff;
  border-radius: 50px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  font-family: "Yantramanav", sans-serif;
  text-transform: capitalize;
}
.btn-hyipox-2:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  transition: all 0.3s ease-in;
  z-index: -1;
  opacity: 0;
}
.btn-hyipox-2:hover {
  color: #fff;
}
.btn-hyipox-2:hover:after {
  opacity: 1;
}

.btn-hyipox-medium {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  color: #fff;
  border-radius: 50px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  font-family: "Yantramanav", sans-serif;
  text-transform: capitalize;
}
.btn-hyipox-medium:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  transition: all 0.3s ease-in;
  z-index: -1;
  opacity: 0;
}
.btn-hyipox-medium:hover {
  color: #fff;
}
.btn-hyipox-medium:hover:after {
  opacity: 1;
}

.btn-hyipox-small {
  display: inline-block;
  height: 30px;
  background: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  padding: 0 25px;
  color: #8e54e9;
  border-radius: 50px;
  text-transform: capitalize;
}

.section-title {
  margin-bottom: 65px;
  text-align: center;
}
.section-title.section-title-2 span.sub-title {
  color: #fff;
}
.section-title.section-title-2 h2 {
  color: #fff;
}
.section-title span.sub-title {
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 900;
  margin-top: -6px;
  font-family: "Yantramanav", sans-serif;
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 12px;
}
.section-title h2 {
  font-weight: 900;
  font-size: 50px;
  line-height: 60px;
  color: #49596d;
  text-transform: capitalize;
}
.section-title h2 span.special {
  font-weight: 700;
  font-family: "Kalam", cursive;
  font-style: italic;
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.c-white {
  color: #fff;
}

span.special {
  padding-right: 4px;
}

@media only screen and (max-width: 991px) {
  .row.reorder-xs {
    /* IE 9 */
    /* Chrome, Safari, Opera */
    transform: rotate(180deg);
    direction: rtl;
  }

  .row.reorder-xs > [class*=col-] {
    /* IE 9 */
    /* Chrome, Safari, Opera */
    transform: rotate(-180deg);
    direction: ltr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row.homepage-about-xs {
    /* IE 9 */
    /* Chrome, Safari, Opera */
    transform: rotate(180deg);
    direction: rtl;
  }

  .row.homepage-about-xs > [class*=col-] {
    /* IE 9 */
    /* Chrome, Safari, Opera */
    transform: rotate(-180deg);
    direction: ltr;
  }
}
/*==========================================
    Preloader
==========================================*/
.preloader {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-to-top-btn a {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: none;
  display: inline-block;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  bottom: 100%;
  right: 30px;
  transition: all 0.3s ease-in;
  border: 1px solid #fff;
}
.back-to-top-btn a.active {
  bottom: 14px;
}
.back-to-top-btn a:hover:after {
  opacity: 0;
}
.back-to-top-btn a:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  z-index: -1;
  transition: all 0.3s ease-in;
}

/*==========================================
    normalize
==========================================*/
html {
  font-family: "Poppins", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin: 0;
  color: #3d5169;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, a {
  line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Yantramanav", sans-serif;
}

p {
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
}

a {
  color: #6253c0;
  text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

/*input and button type focus outline disable*/
input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus,
select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

/**
 * 5.0 - Alignments
 */
.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.notification-alert {
  position: fixed;
  z-index: 55;
  width: 700px;
  left: 50%;
  height: 50px;
  overflow: hidden;
  transform: translateX(-50%);
  display: inline-block;
  top: 100px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
}
.notification-alert.active {
  opacity: 1;
  visibility: visible;
}
.notification-alert .notice-list .single-notice {
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  background: red;
  margin-bottom: 10px;
  opacity: 1;
}
.notification-alert .notice-list .single-notice.hidden {
  opacity: 0;
}

#toast-container {
  width: 1200px !important;
  max-width: 1200px !important;
  right: 50% !important;
  transform: translateX(50%) !important;
  top: 100px !important;
}
#toast-container div {
  box-shadow: none !important;
  opacity: 1 !important;
}
#toast-container div:hover {
  box-shadow: none !important;
}
#toast-container .toast-success {
  background-color: #3bb78f !important;
  background-image: linear-gradient(-155deg, #0bab64 0%, #3bb78f 74%) !important;
  position: relative !important;
}
#toast-container .toast-success:after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
#toast-container .toast-error {
  background-color: #eb4511 !important;
  background-image: linear-gradient(-155deg, #b02e0c 0%, #eb4511 74%) !important;
  position: relative !important;
}
#toast-container .toast-error:after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

body.homepage-3 #toast-container {
  top: 150px !important;
  width: 1220px !important;
  max-width: 1220px !important;
}

@media only screen and (min-width: 320px) and (max-width: 1199px) {
  #toast-container {
    width: 100% !important;
    max-width: 100% !important;
    top: 10px !important;
  }

  body.homepage-3 #toast-container {
    width: 100% !important;
    max-width: 100% !important;
    top: 10px !important;
  }
}
/*==========================================
    Topbar
==========================================*/
.topbar .welcome-text p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}

/*==========================================
    Header
==========================================*/
.header {
  z-index: 3;
  width: 100%;
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 5;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.4);
}
.header .top .welcome-text p {
  margin: 0;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.9);
  padding: 20px 0;
}
.header .top .part-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.header .top .part-right ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header .top .part-right ul li {
  font-size: 14px;
  margin-left: 20px;
}
.header .top .part-right ul li span.simple-text {
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-right: 6px;
}
.header .top .part-right ul li .server-time {
  display: inline-flex;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 6px 10px;
  border-radius: 50px;
}
.header .top .part-right ul li .server-time .single-time {
  margin: 0 10px;
}
@media (max-width: 479px) {
  .header .top .part-right ul li .server-time .single-time {
    margin: 0 8px;
  }
}
.header .top .part-right ul li .server-time .single-time.clock-time {
  white-space: nowrap;
  width: 98px;
}
.header .top .part-right ul li .server-time .single-time span {
  display: inline-block;
  color: #fff;
}
.header .top .part-right ul li .server-time .single-time span.icon {
  margin-right: 6px;
}
.header .top .part-right ul li .server-time .single-time span.text span:after {
  content: ":";
  margin-left: 4px;
}
.header .top .part-right ul li .server-time .single-time span.text span:last-child:after {
  display: none;
}
.header .top .user-panel {
  text-align: right;
  width: 100%;
}
.header .top .user-panel span {
  color: #fff;
  margin-right: 12px;
  font-size: 14px;
}
.header .top .user-panel span a.login-btn {
  display: inline-block;
  margin-right: 12px;
  color: #fff;
  line-height: 28px;
}
.header .top .user-panel .register-btn {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding: 0 30px;
  height: 35px;
  display: inline-block;
  border-radius: 50px;
  background: #fff;
  color: #4776e6;
  font-weight: 500;
}
.header .bottom .logo a {
  display: inline-block;
  height: 45px;
}
.header .bottom .logo a img {
  height: 100%;
}
.header .bottom .mainmenu .navbar {
  padding: 20px 0;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item:hover .nav-link:before, .header .bottom .mainmenu .navbar .navbar-nav .nav-item.active .nav-link:before {
  height: 20px;
  opacity: 0.7;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item.join-now-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item.join-now-btn a {
  background: #fff;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  margin-right: 0;
  padding: 0 40px;
  height: 50px;
  line-height: 46px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50px;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item .nav-link {
  padding: 0;
  margin: 0 20px;
  display: inline-flex;
  color: #fff;
  position: relative;
  font-family: "Yantramanav", sans-serif;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border: none;
  vertical-align: unset;
  height: auto;
  width: auto;
  margin-left: 10px;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item.dropdown .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  color: #fff;
  text-transform: capitalize;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item .dropdown-menu {
  padding: 8px 0px;
  margin: 0;
  border: none;
  border-radius: 0;
  border-top: 10px solid #4776e6;
  border-bottom: 10px solid #4776e6;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  padding: 4px 15px;
  font-size: 18px;
  color: #4776e6;
  font-weight: 500;
}
.header .bottom .mainmenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .header .bottom .mainmenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus .header .bottom .mainmenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  background: transparent;
  color: #8e54e9;
}
.header.header-style-3 {
  border-bottom: none;
}
.header.header-style-3.header-fixed {
  background: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
.header.header-style-3.header-fixed .top {
  display: none;
}
.header.header-style-3.header-fixed .bottom {
  padding: 0;
}
.header.header-style-3 .bottom {
  background: white;
  padding: 0 30px;
  border-radius: 5px;
}
.header.header-style-3 .bottom .mainmenu .navbar .navbar-nav .nav-item .nav-link {
  color: #4776e6;
}
.header.header-style-3 .bottom .mainmenu .navbar .navbar-nav .nav-item.join-now-btn a {
  color: #fff;
}
.header.header-style-3 .bottom .mainmenu .navbar {
  padding: 15px 0;
}
.header.header-style-4 {
  border-bottom: none;
}
@media (min-width: 480px) and (max-width: 523px) {
  .header.header-style-4 .top .part-right ul {
    display: block;
  }
}
.header.header-style-4.header-fixed {
  background: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
.header.header-style-4.header-fixed .top {
  display: none;
}
.header.header-style-4.header-fixed .bottom {
  padding: 0;
}
.header.header-style-4 .bottom {
  background: white;
  padding: 0 30px;
  border-radius: 0;
}
.header.header-style-4 .bottom .mainmenu .navbar .navbar-nav .nav-item .nav-link {
  color: #4776e6;
}
.header.header-style-4 .bottom .mainmenu .navbar .navbar-nav .nav-item.join-now-btn a {
  color: #fff;
}
.header.header-style-4 .bottom .mainmenu .navbar {
  padding: 15px 0;
}
.header.header-style-5 {
  border-bottom: none;
}
.header.header-style-5.header-fixed {
  background: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
.header.header-style-5.header-fixed .top {
  display: none;
}
.header.header-style-5.header-fixed .bottom {
  padding: 0;
}
.header.header-style-5 .bottom {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 0 30px;
  border-radius: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.header.header-style-5 .bottom .mainmenu .navbar .navbar-nav .nav-item .nav-link {
  color: #fff;
}
.header.header-style-5 .bottom .mainmenu .navbar .navbar-nav .nav-item.join-now-btn a {
  color: #fff;
}
.header.header-style-5 .bottom .mainmenu .navbar {
  padding: 15px 0;
}

.header .user-profile {
  padding-left: 30px;
  margin-top: 20px;
}
.header .user-profile .part-img {
  width: 85px;
  border-radius: 50%;
  overflow: hidden;
  border: 6px groove rgba(39, 61, 113, 0.4);
  box-shadow: 0px 0px 18px -3px rgba(255, 255, 255, 0.29);
  margin-bottom: 23px;
  margin-left: -8px;
}
.header .user-profile .part-img img {
  width: 100%;
}
.header .user-profile .user-info {
  position: relative;
  width: 170px;
  padding: 12px 0 13px;
  margin-bottom: 15px;
}
.header .user-profile .user-info:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 999px;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
  box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.15);
  background: #273d71;
}
.header .user-profile .user-info span {
  display: block;
  font-family: "Yantramanav", sans-serif;
}
.header .user-profile .user-info span.user-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 3px;
  color: #fff;
}
.header .user-profile .user-info span.user-balance {
  font-size: 16px;
  font-weight: 300;
  color: #e2e2e2;
}
.header .user-profile .log-out-area {
  position: absolute;
  top: 45px;
  right: 60px;
}
.header .user-profile .log-out-area a {
  display: inline-block;
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  border-radius: 50px;
  font-family: "Yantramanav", sans-serif;
  font-weight: 500;
  background: #fff;
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .header .user-profile .user-info span.user-name {
    font-size: 22px;
    line-height: 32px;
  }

  .header .user-profile .user-info span.user-balance {
    font-size: 18px;
  }

  .header .user-profile .part-img {
    width: 95px;
  }
}
/*==========================================
    banner
==========================================*/
/* :root{
  --url1: url("../img/pexels-pixabay-209230.jpg") center center no-repeat;
  --url2: url("../img/pexels-artempodrez-5025669.jpg") center center no-repeat;
  --banner1 {
    padding: 170px 0 0;
    position: relative;
    background: url("../img/pexels-pixabay-209230.jpg") center center no-repeat;
    background-size: cover;
    z-index: 2;
    transition: all 0.3s ease-in;
  }
  --banner2 {
    padding: 170px 0 0;
    position: relative;
    background: url("../img/pexels-artempodrez-5025669.jpg") center center no-repeat;
    background-size: cover;
    z-index: 2;
    transition: all 0.3s ease-in;
  }
} */

.banner {
  padding: 170px 0 0;
  position: relative;
  background: url("../img/pexels-pixabay-209230.jpg") center center no-repeat;
  background-size: cover;
  z-index: 2;
  transition: all 0.3s ease-in;
}
.banner:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  background: url("../img/banner.png") center center no-repeat;
  background-size: cover;
  z-index: -1;
}
.banner.banner-style-2:after {
  background: linear-gradient(-60deg, rgba(71, 118, 230, 0) -85%, rgba(0, 0, 0, 0.842));
}
.banner.slide-1 {
  background: url("../img/cta-img.jpg") center center no-repeat;
  background-size: cover;
}
.banner.slide-2 {
  background: url("../img/banner-2.jpg") center center no-repeat;
  background-size: cover;
}
.banner.slide-3 {
  background: url("../img/banner-3.jpg") center center no-repeat;
  background-size: cover;
}
.banner .banner-shape {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
}
.banner .banner-shape img {
  height: 100%;
  width: auto;
  max-width: none;
}
.banner .d-banner-tamim {
  flex-direction: column;
  justify-content: space-between;
}
.banner .banner-content h4 {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  margin-top: -8px;
  margin-bottom: 19px;
}
.banner .banner-content h1 {
  font-size: 65px;
  line-height: 75px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 20px;
  margin-top: -11px;
  font-family: "Asap", sans-serif;
}
.banner .banner-content p {
  line-height: 28px;
  color: #fff;
}
.banner .banner-content a {
  margin-top: 24px;
  font-size: 18px;
}
.banner .content {
  outline: none !important;
}
.banner .inner-content .slick-arrow {
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in;
  bottom: 0;
  top: auto;
  opacity: 0;
  transform: none;
  z-index: 5;
}
.banner .inner-content .slick-arrow.slick-next {
  right: 0;
}
.banner .inner-content .slick-arrow.slick-prev {
  left: auto;
  right: 60px;
}
.banner .inner-content:hover .slick-arrow {
  opacity: 1;
}
.banner .banner-statics {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.banner .banner-statics .single-statics {
  display: flex;
  flex-direction: row;
  margin-right: 40px;
}
.banner .banner-statics .single-statics .part-icon {
  margin-right: 20px;
}
.banner .banner-statics .single-statics .part-icon img {
  width: 50px;
}
.banner .banner-statics .single-statics .part-text span {
  display: block;
  font-family: "Yantramanav", sans-serif;
}
.banner .banner-statics .single-statics .part-text span.text {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
}
.banner .banner-statics .single-statics .part-text span.number {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
}
.banner .profit-calculator {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.15);
  padding: 8px 2px 2px 2px;
}
.banner .profit-calculator .calc-header h3.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #49596d;
  color: #fff;
  font-family: "Kalam", cursive;
  font-style: italic;
  margin-top: -6px;
  padding: 20px 30px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
}
.banner .profit-calculator .calc-header h3.title:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -1;
}
.banner .profit-calculator .calc-body {
  padding: 30px;
}
.banner .profit-calculator .part-plan {
  margin-bottom: 20px;
}
.banner .profit-calculator .part-plan h4.title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(73, 89, 109, 0.8);
  margin-bottom: 6px;
  margin-top: -5px;
}
.banner .profit-calculator .part-plan .dropdown a.dropdown-toggle {
  border: 1px solid #ccc;
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 5px;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #888;
}
.banner .profit-calculator .part-plan .dropdown a.dropdown-toggle:focus {
  color: #555;
}
.banner .profit-calculator .part-plan .dropdown .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #49596d;
}
.banner .profit-calculator .part-plan .dropdown .dropdown-item:active, .banner .profit-calculator .part-plan .dropdown .dropdown-item.active {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.banner .profit-calculator .part-amount {
  margin-bottom: 20px;
}
.banner .profit-calculator .part-amount h4.title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(73, 89, 109, 0.8);
  margin-top: -6px;
  margin-bottom: 6px;
  margin-top: -5px;
}
.banner .profit-calculator .part-amount form {
  position: relative;
}
.banner .profit-calculator .part-amount form .currency-symbol {
  position: absolute;
  height: 40px;
  width: 40px;
  left: 0;
  top: 0;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #ccc;
  color: #8e54e9;
  font-weight: 500;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
}
.banner .profit-calculator .part-amount form input {
  width: 100%;
  height: 40px;
  padding: 0 20px 0 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #555;
}
.banner .profit-calculator .part-amount form input:focus {
  color: #888;
}
.banner .profit-calculator .part-amount form button.dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  background: none;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 0 4px 4px 0;
  font-family: "Yantramanav", sans-serif;
  font-size: 16px;
  border-left: 1px solid #ccc;
  color: #555;
  color: #8e54e9;
  font-weight: 500;
}
.banner .profit-calculator .part-amount form button.dropdown-toggle:after {
  border-top-color: #8e54e9;
  border-bottom-color: #8e54e9;
}
.banner .profit-calculator .part-amount form .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #49596d;
}
.banner .profit-calculator .part-amount form .dropdown-item:active, .banner .profit-calculator .part-amount form .dropdown-item.active {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.banner .profit-calculator .cursor-not-allowed {
  position: relative;
  z-index: 1;
  cursor: alias;
}
.banner .profit-calculator .fa-check {
  margin-left: 10px;
  color: #499a57;
  opacity: 0;
  transition: all 0.3s ease-in;
  visibility: hidden;
  position: relative;
  z-index: 5;
}
.banner .profit-calculator .calculate-all {
  width: 140px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: 0;
  color: #fff;
  font-family: "Yantramanav", sans-serif;
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-transform: capitalize;
}
.banner .profit-calculator .calculate-all.calculated {
  pointer-events: none;
}
.banner .profit-calculator .calculate-all.calculated:after {
  opacity: 1;
}
.banner .profit-calculator .calculate-all:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #499a57;
  z-index: -1;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.banner .profit-calculator .part-result {
  position: relative;
  z-index: 2;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  padding: 30px;
}
.banner .profit-calculator .part-result:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -1;
}
.banner .profit-calculator .part-result ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.banner .profit-calculator .part-result ul li {
  font-family: "Yantramanav", sans-serif;
  text-align: left;
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  border-left: 1px solid rgba(221, 221, 221, 0.2);
  border-bottom: 1px solid rgba(221, 221, 221, 0.2);
  justify-content: center;
}
.banner .profit-calculator .part-result ul li:nth-child(1), .banner .profit-calculator .part-result ul li:nth-child(3) {
  border-left: none;
  justify-content: flex-start;
}
.banner .profit-calculator .part-result ul li:nth-child(3), .banner .profit-calculator .part-result ul li:nth-child(4) {
  border-bottom: none;
  padding-top: 20px;
}
.banner .profit-calculator .part-result ul li:nth-child(3) .text .number, .banner .profit-calculator .part-result ul li:nth-child(4) .text .number {
  margin-bottom: -10px;
}
.banner .profit-calculator .part-result ul li:nth-child(2), .banner .profit-calculator .part-result ul li:nth-child(4) {
  justify-content: flex-end;
}
.banner .profit-calculator .part-result ul li:nth-child(1), .banner .profit-calculator .part-result ul li:nth-child(2) {
  padding-bottom: 11px;
}
.banner .profit-calculator .part-result ul li:nth-child(1) .text .title, .banner .profit-calculator .part-result ul li:nth-child(2) .text .title {
  margin-top: -3px;
}
.banner .profit-calculator .part-result ul li .icon {
  width: 32px;
  margin-right: 15px;
}
.banner .profit-calculator .part-result ul li span {
  display: block;
}
.banner .profit-calculator .part-result ul li span.title {
  font-size: 16px;
  line-height: 20px;
  color: #ddd;
  margin-top: -3px;
}
.banner .profit-calculator .part-result ul li span.title br {
  display: none;
}
.banner .profit-calculator .part-result ul li span.number {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.banner .banner-content .inner-content .content h4 {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  margin-top: -8px;
  margin-bottom: 19px;
}
.banner .banner-content .inner-content .content h1 {
  font-size: 65px;
  line-height: 75px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 20px;
  margin-top: -11px;
  font-family: "Asap", sans-serif;
}
.banner .banner-content .inner-content .content p {
  line-height: 28px;
  color: #fff;
}
.banner .banner-content .inner-content .content a {
  margin-top: 24px;
  font-size: 18px;
}

/*==========================================
    about
==========================================*/
.about {
  padding: 0 0 79px;
  position: relative;
}
.about:after {
  position: absolute;
  content: "";
  left: 40%;
  top: 0;
  width: 60%;
  background: url(../img/lottery-ticket-shape.png) center bottom no-repeat;
  background-size: cover;
  bottom: 50px;
  z-index: -2;
}
.about .part-text h2 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 900;
  color: #49596d;
  margin-top: -8px;
  margin-bottom: 28px;
}
.about .part-text h2 span.special {
  font-weight: 700;
  font-family: "Kalam", cursive;
  font-style: italic;
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about .part-text p {
  font-size: 16px;
  line-height: 28px;
  color: #555;
}
.about .part-text p span.spc {
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.about .part-text p span.spc:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 5px;
  width: 100%;
  height: 1px;
  background: #555;
}
.about .part-text ul {
  margin: 30px 0 20px;
}
.about .part-text ul li {
  font-size: 16px;
  line-height: 34px;
  color: #555;
  font-weight: 500;
}
.about .part-text ul li i {
  color: #8e54e9;
  margin-right: 8px;
}
.about .part-text a {
  margin-top: 26px;
  display: inline-grid;
}
.about .part-feature {
  position: relative;
}
.about .part-feature:after {
  position: absolute;
  content: "";
  left: calc(65px / 2);
  top: 0;
  width: 1px;
  background: #8e54e9;
  bottom: 100px;
  z-index: -1;
  opacity: 0.15;
}
.about .part-feature .single-feature {
  margin-bottom: 16px;
}
.about .part-feature .single-feature .feature-icon {
  width: 75px;
  height: 75px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(142, 84, 233, 0.5);
  float: left;
  margin-right: 30px;
  position: relative;
}
.about .part-feature .single-feature .feature-icon:after {
  position: absolute;
  content: "";
  left: -12px;
  top: -30px;
  bottom: -30px;
  right: 0;
  background: url(../img/time-bg.png) center center no-repeat;
  background-size: contain;
  z-index: -1;
}
.about .part-feature .single-feature .feature-icon img {
  width: 100%;
}
.about .part-feature .single-feature .feature-text {
  display: table-cell;
}
.about .part-feature .single-feature .feature-text h3 {
  font-size: 24px;
  font-weight: 900;
  display: inline-block;
  color: #49596d;
}
.about .part-feature .single-feature .feature-text p {
  font-size: 16px;
  line-height: 28px;
  color: #555;
}

.statics .all-statics {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 60px;
  margin: 0 -100px;
  border-radius: 10px;
  z-index: 2;
  position: relative;
}
.statics .all-statics:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../img/banner-bg.png") center center repeat;
  background-size: 85%;
  z-index: -1;
}
.statics .all-statics div div:last-child .single-statics {
  border-right: none;
}
.statics .all-statics .single-statics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-right: 1px dashed #fff;
}
.statics .all-statics .single-statics .part-img {
  margin-right: 30px;
}
.statics .all-statics .single-statics .part-img img {
  width: 60px;
}
.statics .all-statics .single-statics .part-text {
  color: #fff;
}
.statics .all-statics .single-statics .part-text .counter {
  display: block;
  font-size: 50px;
  line-height: 60px;
  font-weight: 300;
  margin-top: -12px;
  font-family: "Yantramanav", sans-serif;
}
.statics .all-statics .single-statics .part-text .title {
  font-size: 20px;
  display: block;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Yantramanav", sans-serif;
  margin-top: -4px;
}

/*==========================================
    Pricing plan
==========================================*/
.pricing-plan {
  padding: 120px 0;
}
.pricing-plan .single-plan {
  background: url("../img/price-plan-bg-1.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 42px 0 43px;
}
.pricing-plan .single-plan:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -2;
  opacity: 0.95;
}
.pricing-plan .single-plan:before {
  position: absolute;
  content: "";
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  background: #fff;
  z-index: -1;
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pricing-plan .single-plan:hover:before, .pricing-plan .single-plan.active:before {
  opacity: 0;
}
.pricing-plan .single-plan:hover h3, .pricing-plan .single-plan.active h3 {
  background: -webkit-linear-gradient(-19deg, #fff 10%, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricing-plan .single-plan:hover .plan-icon img, .pricing-plan .single-plan.active .plan-icon img {
  filter: brightness(100);
}
.pricing-plan .single-plan:hover .feature-list ul li, .pricing-plan .single-plan.active .feature-list ul li {
  background: -webkit-linear-gradient(-19deg, #fff 10%, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricing-plan .single-plan:hover .feature-list ul li i, .pricing-plan .single-plan.active .feature-list ul li i {
  background: -webkit-linear-gradient(-19deg, #fff 10%, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricing-plan .single-plan:hover .price-info span.parcent, .pricing-plan .single-plan:hover .price-info span.price, .pricing-plan .single-plan.active .price-info span.parcent, .pricing-plan .single-plan.active .price-info span.price {
  background: -webkit-linear-gradient(-19deg, #fff 10%, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricing-plan .single-plan:hover .price-button, .pricing-plan .single-plan.active .price-button {
  color: #4776e6;
}
.pricing-plan .single-plan:hover .price-button:after, .pricing-plan .single-plan.active .price-button:after {
  opacity: 1;
  background: linear-gradient(-19deg, #fff 10%, #fff);
}
.pricing-plan .single-plan:hover .price-button:hover:after, .pricing-plan .single-plan.active .price-button:hover:after {
  opacity: 1;
}
.pricing-plan .single-plan h3 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 800;
  color: #49596d;
  margin-bottom: 31px;
  transition: all 0.3s ease-in;
}
.pricing-plan .single-plan .plan-icon {
  height: 75px;
  margin-bottom: 29px;
}
.pricing-plan .single-plan .plan-icon img {
  height: 100%;
}
.pricing-plan .single-plan .feature-list {
  display: inline-block;
}
.pricing-plan .single-plan .feature-list ul {
  text-align: left;
}
.pricing-plan .single-plan .feature-list ul li {
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  color: #555;
}
.pricing-plan .single-plan .feature-list ul li i {
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 6px;
}
.pricing-plan .single-plan .price-info {
  margin-bottom: 36px;
}
.pricing-plan .single-plan .price-info span {
  display: block;
}
.pricing-plan .single-plan .price-info span.parcent {
  font-size: 60px;
  font-weight: 400;
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 4px;
  margin-bottom: -4px;
  font-family: "Kalam", cursive;
}
.pricing-plan .single-plan .price-info span.price {
  font-size: 16px;
  font-weight: 600;
  color: #49596d;
}
.pricing-plan .single-plan .price-info span.price small {
  font-size: 16px;
  font-weight: 500;
}
.pricing-plan .single-plan .price-button:after {
  transition: all 0.3s ease-in;
}
.pricing-plan .single-plan .price-button:hover:after {
  opacity: 0;
}

.cta {
  background: url(../img/cta-img.jpg) center center no-repeat;
  background-size: cover;
  padding: 120px 0;
  position: relative;
  z-index: 2;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
.cta:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  background: url(../img/banner-bg.png) center center no-repeat;
  background-size: 90%;
  z-index: -1;
  opacity: 1;
}
.cta .cta-bg .cta-text h2 {
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 12px;
  color: #fff;
  text-transform: capitalize;
  margin-top: -10px;
}
.cta .cta-bg .cta-text p {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}
.cta .cta-bg .cta-text p.marked {
  font-style: italic;
  font-size: 18px;
  line-height: 30px;
}
.cta .cta-bg .cta-text .cta-btn {
  background: #fff;
  color: #8e54e9;
  font-weight: 600;
  font-size: 18px;
  padding: 0 40px;
  margin-top: 16px;
  display: inline-flex;
}
.cta .cta-bg .cta-text .cta-btn:after {
  display: none;
}
.cta .cta-bg .part-video {
  position: relative;
  padding: 12px;
  width: 320px;
}
.cta .cta-bg .part-video:after {
  position: absolute;
  content: "";
  top: 4px;
  background: #fff;
  left: 6px;
  right: 6px;
  bottom: 6px;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
          clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.cta .cta-bg .part-video img {
  position: relative;
  z-index: 2;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
          clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.cta .cta-bg .part-video button.play-video {
  border: none;
  color: #fff;
  width: 60px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 60px;
  position: absolute;
  z-index: 2;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  border-radius: 50%;
}
.cta .cta-bg .part-video button.play-video:before {
  position: absolute;
  content: "";
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  background: #fff;
  opacity: 0.4;
  z-index: -2;
  border-radius: 50%;
}
.cta .cta-bg .part-video button.play-video:after {
  position: absolute;
  content: "";
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
  z-index: -1;
}

.team {
  padding: 120px 0;
  background: url("../img/team-bg.png") center center no-repeat;
  background-size: cover;
}
.team .single-member {
  text-align: center;
}
.team .single-member .img-box {
  overflow: hidden;
  padding: 10px 0 10px;
  margin-bottom: 23px;
}
.team .single-member .part-img {
  position: relative;
  width: 300px;
  display: inline-block;
}
.team .single-member .part-img .view-btn {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  left: 50%;
  top: -10%;
  margin-left: -25px;
  margin-top: -25px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  z-index: 2;
  border-bottom: 1px dashed #fff;
  transition: all 0.3s ease-in;
  opacity: 0;
  visibility: hidden;
}
.team .single-member .part-img:hover .view-btn {
  top: 50%;
  opacity: 1;
  visibility: visible;
}
.team .single-member .part-img:hover img.border {
  opacity: 0;
}
.team .single-member .part-img:hover:after {
  top: 0;
}
.team .single-member .part-img:after {
  position: absolute;
  content: "";
  left: 0;
  top: calc(100% + 10px);
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  transition: all 0.3s ease-in;
  opacity: 0.8;
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
          clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.team .single-member .part-img:before {
  position: absolute;
  content: "";
  left: -6px;
  top: -6px;
  right: -6px;
  bottom: -6px;
  opacity: 0.5;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
          clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.team .single-member .part-img img {
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
          clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.team .single-member .part-text {
  text-align: center;
}
.team .single-member .part-text span {
  display: block;
}
.team .single-member .part-text span.name {
  font-size: 24px;
  font-weight: 670;
  top: calc( 100% -10px);
  margin-bottom: 5px;
  font-family: "Yantramanav", sans-serif;
}
.team .single-member .part-text span.invested-amount {
  color: rgba(73, 89, 109, 0.8);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 22px;
}
.team .single-member .part-text .paid-from {
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  color: #fff;
  padding: 5px 30px;
  display: inline-flex;
  border-radius: 6px;
  font-family: "Yantramanav", sans-serif;
}
.team .single-member .part-text .paid-from .paymethod-logo {
  width: auto;
  margin-left: 15px;
}
.team .single-member .part-text .paid-from .paymethod-logo img {
  height: 18px;
}

.transaction {
  padding: 120px 0;
  background-image: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  position: relative;
}
.transaction:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../img/map.png") center center no-repeat;
  background-size: 70%;
  opacity: 0.05;
  z-index: 0;
}
.transaction .sub-title {
  color: #fff;
  -webkit-text-fill-color: white !important;
  font-weight: 500 !important;
}
.transaction .part-text h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 12px;
  color: #fff;
  text-transform: capitalize;
  margin-top: -7px;
}
.transaction .part-text p {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}
.transaction .part-text p.marked {
  font-style: italic;
  font-size: 16px;
  line-height: 28px;
}
.transaction .part-text .cta-btn {
  background: #fff;
  color: #8e54e9;
  font-weight: 600;
  font-size: 18px;
  padding: 0 40px;
  margin-top: 16px;
  display: inline-flex;
}
.transaction .part-text .cta-btn:after {
  display: none;
}
.transaction .transaction-list {
  padding: 20px 20px 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-right: -30px;
  border-radius: 6px;
}
.transaction .transaction-list .single-transaction {
  display: flex;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  background: #fff;
  outline: none !important;
}
.transaction .transaction-list .single-transaction:hover .coin {
  opacity: 1;
}
.transaction .transaction-list .single-transaction .flag {
  width: 45px;
  margin-right: 30px;
}
.transaction .transaction-list .single-transaction .flag img {
  width: 100%;
}
.transaction .transaction-list .single-transaction .user-info span {
  font-family: "Yantramanav", sans-serif;
}
.transaction .transaction-list .single-transaction .user-info span.name {
  display: block;
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 3px;
  margin-top: -8px;
  color: #49596d;
}
.transaction .transaction-list .single-transaction .user-info span.tr-type, .transaction .transaction-list .single-transaction .user-info span.tr-date {
  color: rgba(73, 89, 109, 0.8);
}
.transaction .transaction-list .single-transaction .user-info span.tr-amount {
  display: block;
  font-family: "Kalam", cursive;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: -9px;
  color: #61b54c;
}
.transaction .transaction-list .single-transaction .coin {
  width: 150px;
  position: absolute;
  right: -50px;
  bottom: -50px;
  opacity: 0.2;
  transition: all 0.3s ease-in;
}
.choosing-reason {
  padding: 120px 0 120px;
  background: url("../img/feature-bg.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
}
.choosing-reason:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 330px;
  background: url("../img/feature-bg.png") center top no-repeat;
  background-size: cover;
  z-index: -1;
}
.choosing-reason .single-reason {
  display: flex;
  margin-bottom: 15px;
}
.choosing-reason .single-reason .icon-box {
  margin-bottom: 30px;
}
.choosing-reason .single-reason .part-icon {
  position: relative;
  display: inline-block;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
  z-index: 2;
  margin: 17px 0 13px;
  width: 60px;
}
.choosing-reason .single-reason .part-icon:after {
  position: absolute;
  content: "";
  left: -25%;
  top: -10px;
  width: 70px;
  height: 70px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  opacity: 0.15;
  border-radius: 30% 20% 50% 50%;
  transform: rotate(45deg);
}
.choosing-reason .single-reason .part-icon:before {
  position: absolute;
  content: "";
  left: 15%;
  top: -10px;
  width: 70px;
  height: 70px;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  opacity: 0.15;
  border-radius: 30% 20% 50% 50%;
  transform: rotate(-170deg);
  z-index: -1;
}
.choosing-reason .single-reason .part-icon img {
  padding: 15px;
}
.choosing-reason .single-reason .part-text {
  margin-top: 16px;
}
.choosing-reason .single-reason .part-text h3.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #49596d;
  margin-bottom: 14px;
}
.choosing-reason .single-reason .part-text p {
  line-height: 28px;
  color: #555;
}
.choosing-reason .part-img {
  position: relative;
  width: 100%;
  z-index: -1;
  top: 60px;
  left: -70px;
}
.choosing-reason .part-img .shadow-shape {
  position: relative;
  display: inline-block;
  width: 60px;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
}
.choosing-reason .part-img .shadow-shape:after {
  position: absolute;
  content: "";
  left: -100px;
  top: -250px;
  width: 290px;
  height: 290px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  opacity: 0.06;
  border-radius: 30% 20% 50% 50%;
  transform: rotate(45deg);
}
.choosing-reason .part-img .shadow-shape:before {
  position: absolute;
  content: "";
  right: -250px;
  top: -250px;
  width: 290px;
  height: 290px;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  opacity: 0.06;
  border-radius: 30% 20% 50% 50%;
  transform: rotate(-170deg);
  z-index: -1;
}
.choosing-reason .part-img img {
  width: 300px;
  max-width: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.choosing-reason .part-right {
  text-align: right;
}
.choosing-reason .part-left {
  margin-bottom: 82px;
}
.choosing-reason .part-left .single-reason .icon-box {
  margin-right: 50px;
}
.choosing-reason .part-right {
  margin-bottom: 82px;
}
.choosing-reason .part-right .single-reason .icon-box {
  order: 2;
  margin-left: 50px;
}

.testimonial {
  padding: 120px 0 120px;
  background: url(../img/team-bg.png) center center no-repeat;
  background-size: cover;
}
.testimonial .all-testimonials {
  background: url("../img/testimonial-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 50px 0;
  box-shadow: 0 0 21px -7px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
}
.testimonial .all-testimonials:hover .testi-text-slider .slick-arrow {
  opacity: 1;
}
.testimonial .all-testimonials:hover .testi-text-slider .slick-arrow.slick-next {
  right: -110px;
}
.testimonial .all-testimonials:hover .testi-text-slider .slick-arrow.slick-prev {
  left: -110px;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow {
  width: 50px;
  height: 50px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
  line-height: 50px;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow:before {
  opacity: 1;
  font-size: 20px;
  line-height: 48px;
  color: #8e54e9;
  transition: all 0.3s ease-in;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow:after {
  position: absolute;
  content: "";
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease-in;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow:hover:after {
  opacity: 0;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow:hover:before {
  color: #fff;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow.slick-next {
  right: -140px;
}
.testimonial .all-testimonials .testi-text-slider .slick-arrow.slick-prev {
  left: -140px;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial {
  outline: none;
  text-align: center;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial .quot-icon {
  display: inline-block;
  margin-bottom: 22px;
  width: 40px;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial p {
  font-size: 22px;
  line-height: 36px;
  color: #3f3c57;
  font-weight: 400;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial .part-user {
  display: inline-flex;
  flex-direction: column;
  text-align: end;
  margin-bottom: 26px;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial .user-name, .testimonial .all-testimonials .testi-text-slider .single-testimonial .user-location {
  font-family: "Yantramanav", sans-serif;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial .user-name {
  color: #49596d;
  font-size: 30px;
  font-weight: 300;
  display: inline-block;
  position: relative;
  margin-bottom: -4px;
  right: -10px;
  font-family: "Kalam", cursive;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial .user-name:after {
  content: "";
  right: calc(100% + 20px);
  top: 50%;
  width: 100px;
  height: 1px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  position: absolute;
  opacity: 0.5;
}
.testimonial .all-testimonials .testi-text-slider .single-testimonial .user-location {
  color: #999;
}
.testimonial .all-testimonials .testi-user-slider {
  text-align: center;
  margin-bottom: -12px;
}
.testimonial .all-testimonials .testi-user-slider .slick-list {
  width: 250px;
  transform: none;
  display: inline-block;
}
.testimonial .all-testimonials .testi-user-slider .single-user {
  outline: none;
  position: relative;
  z-index: 2;
  transform: scale(0.7);
  transition: all 0.3s ease-in;
}
.testimonial .all-testimonials .testi-user-slider .single-user.slick-current {
  transform: scale(0.9);
}
.testimonial .all-testimonials .testi-user-slider .single-user:after {
  position: absolute;
  content: "";
  left: -2px;
  right: -2px;
  bottom: -2px;
  top: -2px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -1;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
          clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  margin: 2px 0;
}
.testimonial .all-testimonials .testi-user-slider .single-user img {
  width: 100%;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
          clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  margin: 4px 0;
  padding: 0 2px;
}

.payment-gateway {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 80px 0;
  z-index: 2;
  position: relative;
}
.payment-gateway:after {
  background: url("../img/banner-bg.png") center center repeat;
  background-size: 75%;
  z-index: -1;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.payment-gateway .part-text {
  text-align: left;
}
.payment-gateway .part-text h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 22px;
  color: #fff;
  text-transform: capitalize;
  margin-top: -10px;
}
.payment-gateway .part-text p {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}
.payment-gateway .part-text p.marked {
  font-style: italic;
  font-size: 18px;
  line-height: 30px;
}
.payment-gateway .part-crypto {
  margin-left: -30px;
  position: relative;
  padding: 30px 30px 30px 30px;
}
.payment-gateway .part-crypto:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: white;
  z-index: -1;
  border-radius: 10px;
}
.payment-gateway .part-crypto h3.title {
  font-size: 18px;
  line-height: 30px;
  color: #49596d;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  margin-top: -8px;
  padding-bottom: 6px;
  margin-bottom: 19px;
  display: block;
}
.payment-gateway .part-crypto h3.title:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  background: #49596d;
  opacity: 0.5;
}
.payment-gateway h3.title {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 400;
  position: relative;
  margin-top: -8px;
  padding-bottom: 6px;
  margin-bottom: 19px;
  display: block;
}
.payment-gateway h3.title:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  background: white;
  opacity: 0.5;
}
.payment-gateway .all-payment {
  margin-top: 50px;
}
.payment-gateway .all-payment .single-payment-way {
  background: #fff;
  padding: 15px 20px;
  display: inline-block;
  margin: 0 10px;
  border-radius: 6px;
}
.payment-gateway .all-payment .single-payment-way img {
  height: 50px;
}
.payment-gateway .all-payment .single-payment-way:first-child {
  margin-left: 0;
}
.payment-gateway .all-payment .single-payment-way:last-child {
  margin-right: 0;
}

.how-to-works {
  padding: 60px 0 35px;
  margin-bottom: 120px;
  position: relative;
  z-index: 2;
  position: relative;
  z-index: 2;
}
.how-to-works:after {
  position: absolute;
  content: "";
  left: -60px;
  right: -60px;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
  border-radius: 10px;
  box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.12);
}
.how-to-works .single-system {
  position: relative;
  padding: 0 15px;
}
.how-to-works .single-system .part-icon {
  left: 0;
  position: relative;
  width: 70px;
  float: left;
  margin-right: 30px;
  z-index: 2;
}
.how-to-works .single-system .part-icon img {
  background: #fff;
}
.how-to-works .single-system .part-text {
  display: flow-root;
}
.how-to-works .single-system .part-text h4.title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #49596d;
}
.how-to-works .single-system .part-text p {
  color: #555;
  line-height: 28px;
}

.blog {
  padding: 120px 0 90px;
  background: url(../img/feature-bg.jpg) center center no-repeat;
  background-size: cover;
}
.blog .single-blog {
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px 5px rgba(56, 56, 56, 0.08);
  border-radius: 10px;
}
.blog .single-blog .part-img {
  position: relative;
}
.blog .single-blog .part-img:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 10px;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.blog .single-blog .part-img img {
  width: 100%;
  border-radius: 5px;
}
.blog .single-blog .part-img a {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.blog .single-blog .part-img a i {
  background: #fff;
  z-index: 2;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: #555;
}
.blog .single-blog .part-img .post-date {
  display: inline-block;
  background: #8e54e9;
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 1px solid #fff;
  z-index: 3;
  transition: all 0.3s ease-in;
}
.blog .single-blog .part-img .post-date span {
  display: block;
  color: #fff;
  text-align: center;
  line-height: 100%;
  transition: all 0.3s ease-in;
}
.blog .single-blog .part-img .post-date span.date {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}
.blog .single-blog .part-img .post-date span.month {
  font-size: 12px;
  text-transform: uppercase;
}
.blog .single-blog .part-text .title {
  font-family: "Yantramanav", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #555;
  margin-top: 24px;
  display: block;
  margin-bottom: 16px;
  line-height: 30px;
}
.blog .single-blog .part-text p {
  font-size: 16px;
  line-height: 28px;
  color: #555;
  opacity: 0.8;
}
.blog .single-blog .part-text .share-btn {
  font-size: 16px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  background: #fff;
  color: #555;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  position: relative;
  margin-top: 16px;
  box-shadow: 0px 5px 7px 2px rgba(56, 56, 56, 0.1);
}
.blog .single-blog .part-text .share-btn:after {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border: 1px solid rgba(56, 56, 56, 0.2);
  border-radius: 50px;
}
.blog .single-blog:hover .part-img:after {
  opacity: 0.6;
}
.blog .single-blog:hover .part-img a {
  opacity: 1;
  visibility: visible;
}
.blog .single-blog:hover .part-img .post-date {
  background: #fff;
}
.blog .single-blog:hover .part-img .post-date span {
  color: #8e54e9;
}

.footer .footer-top {
  background: #111;
  padding: 120px 0 115px;
}
.footer .about-widget a.logo {
  display: inline-block;
  width: 150px;
  margin-bottom: 32px;
}
.footer .about-widget a.logo img {
  width: 100%;
}
.footer .about-widget p {
  color: #ababab;
  line-height: 28px;
}
.footer .about-widget .social-links {
  margin-top: 32px;
}
.footer .about-widget .social-links ul li {
  display: inline-block;
}
.footer .about-widget .social-links ul li a {
  display: inline-block;
  background: #242424;
  color: #8e54e9;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 6px;
}
.footer .about-widget .social-links ul li a.active, .footer .about-widget .social-links ul li a:hover {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.footer .link-widget h4.title {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  margin-top: -6px;
  margin-bottom: 23px;
  letter-spacing: 0;
}
.footer .link-widget ul li {
  padding: 5px 0;
}
.footer .link-widget ul li a {
  display: inline-block;
  color: #ababab;
  font-family: "Yantramanav", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  transition: all 0.3s ease-in;
}
.footer .link-widget ul li a:hover {
  color: #8e54e9;
}
.footer .newsletter-widget h4.title {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  margin-top: -6px;
  margin-bottom: 15px;
  letter-spacing: 0;
  font-weight: 500;
}
.footer .newsletter-widget .newsletter-form {
  position: relative;
  z-index: 2;
  padding-bottom: 1px;
  margin-bottom: 25px;
}
.footer .newsletter-widget .newsletter-form:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 51px;
  background: #8e54e9;
  z-index: -1;
  opacity: 0.5;
}
.footer .newsletter-widget .newsletter-form input {
  background: #111;
  border: none;
  width: 100%;
  padding: 0 90px 0 0;
  height: 50px;
  color: #999;
}
.footer .newsletter-widget .newsletter-form .def-btn {
  display: inline-block;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: none;
  height: 40px;
  color: #fff;
  width: 140px;
  border-radius: 50px;
  margin-top: 20px;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease-in;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}
.footer .newsletter-widget .newsletter-form .def-btn:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  z-index: -1;
  transition: all 0.3s ease-in;
}
.footer .newsletter-widget .newsletter-form .def-btn:hover:after {
  opacity: 0;
}
.footer .newsletter-widget p {
  color: #ababab;
  line-height: 28px;
}
.footer .copyright-area {
  background: #242424;
  text-align: center;
  padding: 25px 0;
}
.footer .copyright-area p {
  color: #999;
  margin-bottom: 0;
  line-height: 28px;
}
.footer .copyright-area p a {
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0;
}

.mobile-navigation-bar {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  position: fixed;
  z-index: 55;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.mobile-navigation-bar ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.mobile-navigation-bar ul li {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-navigation-bar ul li a {
  display: inline-block;
  padding: 10px 15px;
  position: relative;
  border: 1px solid transparent;
  top: 0;
  transition: all 0.3s ease-in;
}
.mobile-navigation-bar ul li a:focus {
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  top: -10px;
  border-radius: 10px;
}
.mobile-navigation-bar ul li a img {
  height: 30px;
  width: auto;
  max-width: none;
}

.breadcrumb-oitila {
  background: url(../img/breadcrumb-bg.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 144px;
}
.breadcrumb-oitila.db-breadcrumb .part-txt {
  padding: 50px 0;
}
@media (max-width: 479px) {
  .breadcrumb-oitila.db-breadcrumb .part-txt {
    padding: 30px 0 !important;
  }
}
.breadcrumb-oitila.db-breadcrumb .part-txt h1 {
  font-size: 34px;
  line-height: 44px;
}
@media (max-width: 768px) {
  .breadcrumb-oitila.db-breadcrumb .part-txt h1 {
    font-size: 34px !important;
    line-height: 44px;
  }
}
@media (max-width: 479px) {
  .breadcrumb-oitila.db-breadcrumb .part-txt h1 {
    font-size: 30px !important;
    line-height: 40px;
  }
}
.breadcrumb-oitila.db-breadcrumb .part-txt ul li {
  font-size: 16px;
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 320px) and (max-width: 991px) {
  .breadcrumb-oitila.db-breadcrumb .db-user-profile {
    display: none;
  }
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile .part-data span.name {
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 7px;
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile .part-data ul li {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile .part-data ul li span.number {
  font-weight: 500;
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile .part-data ul li span.number:before {
  content: ":";
  margin-right: 6px;
  margin-left: 4px;
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile .part-img {
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  padding: 4px;
  margin-right: 30px;
}
.breadcrumb-oitila.db-breadcrumb .db-user-profile .part-img img {
  border-radius: 50%;
  width: 100%;
}
.breadcrumb-oitila .part-txt {
  padding: 120px 0;
}
.breadcrumb-oitila .part-txt h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  margin-top: -9px;
  margin-bottom: 15px;
}
.breadcrumb-oitila .part-txt ul {
  display: flex;
}
.breadcrumb-oitila .part-txt ul li {
  font-size: 18px;
  line-height: 100%;
  text-transform: capitalize;
  color: #fff;
  padding-right: 30px;
  margin-bottom: 2px;
  position: relative;
}
.breadcrumb-oitila .part-txt ul li:after {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: 2px;
  height: 100%;
  background: #fff;
}
.breadcrumb-oitila .part-txt ul li:last-child {
  padding-right: 0;
}
.breadcrumb-oitila .part-txt ul li:last-child:after {
  display: none;
}

/*==========================================
    about page
==========================================*/
.about.about-inner {
  padding-top: 120px;
}
.about.about-inner .how-to-works {
  display: none;
}

.team-inner {
  padding: 120px 0;
  background: #fff;
}
.team-inner .single-box {
  padding: 30px;
  box-shadow: 0px 0px 25px -5px rgba(33, 33, 33, 0.1);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.team-inner .single-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
.team-inner .single-box:after {
  content: "";
  position: absolute;
  top: -150px;
  left: -15px;
  width: 300px;
  height: 300px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 40%;
  z-index: -1;
}
.team-inner .single-box .part-img {
  border: 5px solid #4776e6;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 5px 15px -5px rgba(71, 118, 230, 0.5);
  width: 170px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.team-inner .single-box .part-txt {
  text-align: center;
}
.team-inner .single-box .part-txt h3 {
  font-size: 24px;
  line-height: 100%;
  font-weight: 650;
  text-transform: capitalize;
  margin-top: -2px;
  margin-bottom: 15px;
}
.team-inner .single-box .part-txt p {
  font-size: 16px;
  line-height: 100%;
  text-transform: capitalize;
  color: rgba(33, 33, 33, 0.75);
  margin-top: -1px;
  margin-bottom: 17px;
}
.team-inner .single-box .part-txt .social {
  padding-bottom: 5px;
}
.team-inner .single-box .part-txt .social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 42px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  z-index: 2;
}
.team-inner .single-box .part-txt .social a:last-child {
  margin-right: 0;
}
.team-inner .single-box .part-txt .social a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  border-radius: 50%;
  opacity: 0;
  transition: 0.4s;
  z-index: -1;
}
.team-inner .single-box .part-txt .social a:hover:after {
  opacity: 1;
}

.contact {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 120px 0;
  z-index: 2;
  position: relative;
}
.contact .section-title .sub-title {
  -webkit-text-fill-color: #fff;
}
.contact .section-title h2 .special {
  background: transparent;
  -webkit-text-fill-color: #fff;
}
.contact:after {
  background: url("../img/banner-bg.png") center center repeat;
  background-size: 75%;
  z-index: -1;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.contact .bg-tamim {
  padding: 50px 0;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.contact .bg-tamim .part-form form input {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  background: transparent;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  margin-bottom: 20px;
}
.contact .bg-tamim .part-form form input::placeholder {
  color: #ffffffcc;
}
.contact .bg-tamim .part-form form textarea {
  width: 100%;
  height: 170px;
  padding: 20px 30px;
  background: transparent;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  margin-bottom: 20px;
}
.contact .bg-tamim .part-form form textarea::placeholder {
  color: #ffffffcc;
}
.contact .bg-tamim .part-form form button {
  background: #fff;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  margin-right: 0;
  padding: 0 40px;
  height: 50px;
  line-height: 46px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
}
.contact .bg-tamim .part-address .single-address {
  margin-bottom: 26px;
}
.contact .bg-tamim .part-address .single-address:last-child {
  margin-bottom: 0;
}
.contact .bg-tamim .part-address .single-address h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
  margin-top: -7px;
  margin-bottom: 15px;
}
.contact .bg-tamim .part-address .single-address p {
  color: #fff;
  line-height: 30px;
}

/*==========================================
    Blog page
==========================================*/
.blog-page {
  padding: 120px 0;
  background: #fff;
}
.blog-page .blog-sidebar .single-sidebar {
  box-shadow: 0px 0px 11px 7px rgba(56, 56, 56, 0.08);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
}
.blog-page .blog-sidebar .single-sidebar:last-child {
  margin-bottom: 0;
}
.blog-page .blog-sidebar .single-sidebar .title {
  font-size: 20px;
  text-transform: uppercase;
  color: #383838;
  display: inline-block;
  margin-bottom: 22px;
  position: relative;
  padding-left: 20px;
}
.blog-page .blog-sidebar .single-sidebar .title:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
}
.blog-page .blog-sidebar .single-sidebar form {
  position: relative;
}
.blog-page .blog-sidebar .single-sidebar form input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #8e54e9;
  padding: 10px 0;
  text-transform: capitalize;
  padding-right: 40px;
}
.blog-page .blog-sidebar .single-sidebar form button {
  background: transparent;
  border: none;
  color: #8e54e9;
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}
.blog-page .blog-sidebar .single-sidebar .category-sidebar li a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  color: #383838;
  opacity: 0.7;
  line-height: 34px;
  transition: all 0.3s ease-in;
}
.blog-page .blog-sidebar .single-sidebar .category-sidebar li a .number {
  font-weight: 400;
}
.blog-page .blog-sidebar .single-sidebar .category-sidebar li a:hover {
  color: #8e54e9;
  opacity: 1;
}
.blog-page .blog-sidebar .single-sidebar .popular-news .single-post {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding: 25px;
  box-shadow: 0px 0px 11px 7px rgba(56, 56, 56, 0.08);
  border-radius: 10px;
}
.blog-page .blog-sidebar .single-sidebar .popular-news .single-post:last-child {
  margin-bottom: 0;
}
.blog-page .blog-sidebar .single-sidebar .popular-news .single-post .part-img {
  width: 80px;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.blog-page .blog-sidebar .single-sidebar .popular-news .single-post .part-text a {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #383838;
  opacity: 0.8;
  transition: all 0.3s ease-in;
  line-height: 24px;
}
.blog-page .blog-sidebar .single-sidebar .popular-news .single-post .part-text a:hover {
  color: #8e54e9;
  opacity: 1;
}
.blog-page .blog-sidebar .single-sidebar .tags a {
  display: inline-block;
  border: 1px solid #8e54e9;
  color: rgba(142, 84, 233, 0.9);
  text-transform: uppercase;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 2px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in;
}
.blog-page .blog-sidebar .single-sidebar .tags a:hover {
  background-color: #8e54e9;
  color: #fff;
}
.blog-page .blog {
  background: #fff;
  padding: 0;
}
.blog-page .blog .single-blog {
  margin-bottom: 30px;
}
.blog-page .blog nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.blog-page .blog nav ul li {
  width: 40px;
  margin-right: 10px;
}
.blog-page .blog nav ul li:first-child {
  width: auto;
}
.blog-page .blog nav ul li:first-child a {
  padding: 0 15px;
}
.blog-page .blog nav ul li:last-child {
  width: auto;
  margin-right: 0;
}
.blog-page .blog nav ul li:last-child a {
  padding: 0 15px;
  background-color: #8e54e9;
  color: #fff;
}
.blog-page .blog nav ul li a {
  display: inline-block;
  text-align: center;
  border: 1px solid #8e54e9;
  color: rgba(142, 84, 233, 0.8);
  text-transform: uppercase;
  padding: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease-in;
}
.blog-page .blog nav ul li a:hover {
  background-color: #8e54e9;
  color: #fff;
}

/*==========================================
    Blog details page
==========================================*/
.blog-details-page .blog {
  box-shadow: 0px 0px 11px 7px rgba(56, 56, 56, 0.08);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
}
.blog-details-page .blog .part-img {
  position: relative;
}
.blog-details-page .blog .part-img img {
  width: 100%;
  border-radius: 10px;
}
.blog-details-page .blog .part-img .post-date {
  display: inline-block;
  background: #8e54e9;
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 2px solid #fff;
  z-index: 3;
  transition: all 0.3s ease-in;
}
.blog-details-page .blog .part-img .post-date span {
  display: block;
  color: #fff;
  text-align: center;
  line-height: 100%;
  transition: all 0.3s ease-in;
}
.blog-details-page .blog .part-img .post-date span.date {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 4px;
}
.blog-details-page .blog .part-img .post-date span.month {
  font-size: 14px;
  text-transform: uppercase;
}
.blog-details-page .blog .part-text a.title {
  font-size: 28px;
  font-weight: 500;
  color: #383838;
  margin-top: 24px;
  display: block;
  margin-bottom: 16px;
  line-height: 30px;
}
.blog-details-page .blog .part-text .meta-info {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  margin-bottom: 20px;
}
.blog-details-page .blog .part-text .meta-info li {
  display: inline-block;
  margin-right: 20px;
  color: #383838;
  opacity: 0.7;
  font-size: 14px;
}
.blog-details-page .blog .part-text .meta-info li a {
  color: #383838;
}
.blog-details-page .blog .part-text .meta-info li i {
  margin-right: 6px;
  color: #8e54e9;
}
.blog-details-page .blog .part-text p {
  font-size: 17px;
  line-height: 28px;
  opacity: 0.8;
  margin-bottom: 30px;
  overflow: hidden;
}
.blog-details-page .blog .part-text p.marked {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  opacity: 1;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}
.blog-details-page .blog .part-text p.marked .qout {
  position: absolute;
  font-size: 150px;
  opacity: 0.1;
  left: 20px;
  z-index: -1;
  top: -20px;
}
.blog-details-page .blog .part-text .part-img {
  margin-bottom: 30px;
}
.blog-details-page .blog .part-text .share-btn {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-right: 6px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  background: #fff;
  color: #383838;
  text-align: center;
  border-radius: 50px;
  position: relative;
  box-shadow: 0px 5px 7px 2px rgba(56, 56, 56, 0.1);
}
.blog-details-page .blog .part-text .share-btn:after {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border: 1px solid rgba(56, 56, 56, 0.2);
  border-radius: 50px;
}
.blog-details-page .blog:hover .part-img .post-date {
  background-color: #fff;
}
.blog-details-page .blog:hover .part-img .post-date span {
  color: #8e54e9;
}
.blog-details-page .comment-area {
  box-shadow: 0px 0px 11px 7px rgba(56, 56, 56, 0.08);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
}
.blog-details-page .comment-area .title {
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  color: #383838;
  display: inline-block;
  margin-bottom: 22px;
  position: relative;
  padding-left: 20px;
}
.blog-details-page .comment-area .title:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
}
.blog-details-page .comment-area .single-comment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px 0;
  border-top: 1px solid #ddd;
  padding-right: 100px;
  position: relative;
}
.blog-details-page .comment-area .single-comment:last-child {
  padding-bottom: 0;
}
.blog-details-page .comment-area .single-comment.reply {
  padding-left: 30px;
}
.blog-details-page .comment-area .single-comment .part-img {
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 6px solid #fff;
  box-shadow: 0px 3px 7px 2px rgba(56, 56, 56, 0.1);
  margin-right: 30px;
}
.blog-details-page .comment-area .single-comment .part-text span {
  display: block;
}
.blog-details-page .comment-area .single-comment .part-text span.commentor-name {
  font-size: 18px;
  font-weight: 500;
  color: #383838;
  margin-top: -6px;
  margin-bottom: 2px;
}
.blog-details-page .comment-area .single-comment .part-text span.comment-date {
  font-size: 13px;
  opacity: 0.8;
  color: #383838;
  margin-bottom: 9px;
}
.blog-details-page .comment-area .single-comment .part-text p {
  font-size: 16px;
  line-height: 26px;
  opacity: 0.8;
  margin: 0;
}
.blog-details-page .comment-area .single-comment button.reply {
  position: absolute;
  right: 0;
  top: 40px;
  font-size: 14px;
  text-transform: uppercase;
  background: #8e54e9;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  outline: none;
}
.blog-details-page .comment-area .single-comment button.reply:hover {
  background-color: #383838;
}
.blog-details-page .comment-form {
  box-shadow: 0px 0px 11px 7px rgba(56, 56, 56, 0.08);
  border-radius: 10px;
  padding: 30px;
}
.blog-details-page .comment-form .title {
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  color: #383838;
  display: inline-block;
  margin-bottom: 22px;
  position: relative;
  padding-left: 20px;
}
.blog-details-page .comment-form .title:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
}
.blog-details-page .comment-form form input {
  width: 100%;
  height: 50px;
  padding: 0 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.blog-details-page .comment-form form textarea {
  width: 100%;
  height: 200px;
  padding: 12px 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.blog-details-page .comment-form form button {
  height: 50px;
  width: 150px;
  background-color: #8e54e9;
  text-transform: uppercase;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in;
}
.blog-details-page .comment-form form button:hover {
  background-color: #383838;
}

.faq {
  padding: 120px 0 101px;
  background: #fff;
}
.faq .faq-sidebar {
  margin-right: -40px;
}
.faq .faq-sidebar .search-widget form {
  margin-bottom: 30px;
  position: relative;
}
.faq .faq-sidebar .search-widget form input {
  border: 1px solid #8e54e9;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding: 0 30px;
}
.faq .faq-sidebar .search-widget form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: none;
  border-radius: 0 5px 5px 0;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.faq .faq-sidebar .nav {
  border: 1px solid rgba(142, 84, 233, 0.5);
  border-bottom: 0;
  border-radius: 5px;
  overflow: hidden;
}
.faq .faq-sidebar .nav .nav-link {
  border: none;
  color: #212121;
  outline: none;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid rgba(142, 84, 233, 0.5);
  height: 60px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  padding: 0;
  padding-left: 30px;
  font-weight: 500;
  line-height: 60px;
  border-radius: 0;
  transition: 0.3s;
  z-index: 2;
}
.faq .faq-sidebar .nav .nav-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  background: #4776e6;
  opacity: 0;
  transition: all 0.3s ease-in;
  z-index: -1;
}
.faq .faq-sidebar .nav .nav-link:after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 0;
  background: #8e54e9;
  transition: all 0.3s ease-in;
}
.faq .faq-sidebar .nav .nav-link.active {
  background: transparent;
  color: #fff;
}
.faq .faq-sidebar .nav .nav-link.active:before {
  opacity: 1;
}
.faq .faq-sidebar .nav .nav-link.active:after {
  height: 100%;
}
.faq .faq-content {
  margin-left: -40px;
}
.faq .faq-content .tab-pane.show .single-faq {
  transform: scale(1);
}
.faq .faq-content .tab-pane .single-faq {
  box-shadow: 0px 0px 20px 0px rgba(33, 33, 33, 0.1);
  border-radius: 10px;
  padding: 30px 40px 16px;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
  overflow: hidden;
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
}
.faq .faq-content .tab-pane .single-faq h4 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 14px;
  transition: all 0.3s ease-in;
}
.faq .faq-content .tab-pane .single-faq p {
  font-size: 16px;
  line-height: 28px;
  transition: all 0.3s ease-in;
}

.contact.contact-page {
  background: #fff;
  padding-bottom: 0;
  position: initial;
}
.contact.contact-page:after {
  background: transparent;
}
.contact.contact-page .section-title .sub-title {
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact.contact-page .section-title h2 .special {
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact.contact-page .bg-tamim {
  position: relative;
  z-index: 2;
}
.contact.contact-page .bg-tamim:after {
  background: url("../img/banner-bg.png") center center repeat;
  background-size: 75%;
  z-index: -1;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.map {
  margin-top: -250px;
}
.map iframe {
  width: 100%;
  height: 500px;
  margin-bottom: -7px;
}

.register {
  background: #fff;
  padding: 120px 0 120px;
}
.register .reg-body {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(33, 33, 33, 0.1);
  padding: 50px;
  padding-bottom: 28px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
.register .reg-body form .sub-title {
  font-size: 24px;
  color: #fff;
  line-height: 34px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 24px;
  margin-top: -6px;
  text-transform: capitalize;
}
.register .reg-body form .sub-title:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
}
.register .reg-body form .sub-title:after {
  position: absolute;
  content: "";
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
}
.register .reg-body form input[type=text], .register .reg-body form [type=email], .register .reg-body form [type=password] {
  height: 60px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  background: transparent;
  padding: 0 30px;
  color: #fff;
  font-size: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
}
.register .reg-body form input[type=text]::placeholder, .register .reg-body form [type=email]::placeholder, .register .reg-body form [type=password]::placeholder {
  color: #ffffffcc;
}
.register .reg-body form .term-condition {
  margin-top: 30px;
}
.register .reg-body form .term-condition .title {
  font-size: 24px;
  color: #fff;
  line-height: 34px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 24px;
  margin-top: -6px;
  text-transform: capitalize;
}
.register .reg-body form .term-condition .title:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
}
.register .reg-body form .term-condition .title:after {
  position: absolute;
  content: "";
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
}
.register .reg-body form .term-condition p {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: #fff;
  line-height: 28px;
  padding: 30px;
  margin-bottom: 40px;
}
.register .reg-body form .form-check p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}
.register .reg-body form .form-check [type=checkbox] {
  opacity: 0;
}
.register .reg-body form .form-check [type=checkbox]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.register .reg-body form .form-check [type=checkbox]:checked + label:before, .register .reg-body form .form-check [type=checkbox]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 2px solid #8e54e9;
  border-radius: 100%;
  background: #fff;
  margin-left: -6px;
}
.register .reg-body form .form-check [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.register .reg-body form .form-check [type=checkbox]:checked + label:after, .register .reg-body form .form-check [type=checkbox]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
  margin-left: -6px;
}
.register .reg-body form .form-check label {
  color: #fff;
  font-weight: 400;
  display: block;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  margin-bottom: 8px;
}
.register .reg-body form .btn-form {
  letter-spacing: 0.5px;
  float: right;
  background: #fff;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  margin-right: 0;
  padding: 0 40px;
  height: 50px;
  line-height: 46px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
}

.transaction-page {
  padding: 120px 0;
  background: #fff;
}
.transaction-page .table-flex {
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 0px 21px -7px rgba(33, 33, 33, 0.31);
}
.transaction-page .table-flex .table {
  border-collapse: separate;
  border-spacing: 0 0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0;
}
.transaction-page .table-flex .table thead tr {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
.transaction-page .table-flex .table thead tr th {
  padding: 0;
  vertical-align: middle;
  padding: 0 30px;
  height: 60px;
  border: none;
  text-align: center;
  font-size: 18px;
  line-height: 60px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
}
.transaction-page .table-flex .table thead tr th:first-child {
  text-align: left;
}
.transaction-page .table-flex .table tbody tr {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
.transaction-page .table-flex .table tbody tr th {
  padding: 0;
  vertical-align: middle;
  padding: 0 30px;
  height: 70px;
  border: none;
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.transaction-page .table-flex .table tbody tr th .user img {
  width: 45px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(33, 33, 33, 0.5);
  margin-right: 20px;
}
.transaction-page .table-flex .table tbody tr th span {
  display: inline-block;
  font-size: 18px;
  line-height: 70px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
}
.transaction-page .table-flex .table tbody tr td {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0 20px;
  vertical-align: middle;
  height: 70px;
  text-align: center;
  font-size: 16px;
  line-height: 70px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
}
.transaction-page .table-flex .table tbody tr td img {
  width: 35px;
}

.calculation {
  padding: 120px 0;
  background: #eef6fc;
  position: relative;
  z-index: 2;
}
.calculation:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/trans-page-bg.png) center center no-repeat;
  background-size: cover;
  z-index: -1;
}
.calculation:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/banner-bg.png) center center no-repeat;
  background-size: cover;
  z-index: -1;
}
.calculation .part-txt h2 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 900;
  color: #49596d;
  margin-top: -8px;
  margin-bottom: 28px;
}
.calculation .part-txt h2 .special {
  font-weight: 700;
  font-family: "Kalam", cursive;
  font-style: italic;
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  color: transparent;
}
.calculation .part-txt p {
  font-size: 16px;
  line-height: 28px;
  color: #555;
}
.calculation .part-txt p span.spc {
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.calculation .part-txt p span.spc:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 5px;
  width: 100%;
  height: 1px;
  background: #555;
}
.calculation .part-txt ul {
  margin: 30px 0 20px;
}
.calculation .part-txt ul li {
  font-size: 16px;
  line-height: 34px;
  color: #555;
  font-weight: 500;
}
.calculation .part-txt ul li i {
  color: #8e54e9;
  margin-right: 8px;
}
.calculation .part-txt a {
  margin-top: 26px;
  display: inline-grid;
}
.calculation .profit-calculator {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.15);
  padding: 8px 2px 2px 2px;
}
.calculation .profit-calculator .calc-header h3.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #49596d;
  color: #fff;
  font-family: "Kalam", cursive;
  font-style: italic;
  margin-top: -6px;
  padding: 20px 30px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
}
.calculation .profit-calculator .calc-header h3.title:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -1;
}
.calculation .profit-calculator .calc-body {
  padding: 30px;
}
.calculation .profit-calculator .part-plan {
  margin-bottom: 20px;
}
.calculation .profit-calculator .part-plan h4.title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(73, 89, 109, 0.8);
  margin-bottom: 6px;
  margin-top: -5px;
}
.calculation .profit-calculator .part-plan .dropdown a.dropdown-toggle {
  border: 1px solid #ccc;
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 5px;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #888;
}
.calculation .profit-calculator .part-plan .dropdown a.dropdown-toggle:focus {
  color: #555;
}
.calculation .profit-calculator .part-plan .dropdown .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #49596d;
}
.calculation .profit-calculator .part-plan .dropdown .dropdown-item:active, .calculation .profit-calculator .part-plan .dropdown .dropdown-item.active {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.calculation .profit-calculator .part-amount {
  margin-bottom: 20px;
}
.calculation .profit-calculator .part-amount h4.title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(73, 89, 109, 0.8);
  margin-top: -6px;
  margin-bottom: 6px;
  margin-top: -5px;
}
.calculation .profit-calculator .part-amount form {
  position: relative;
}
.calculation .profit-calculator .part-amount form .currency-symbol {
  position: absolute;
  height: 40px;
  width: 40px;
  left: 0;
  top: 0;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #ccc;
  color: #8e54e9;
  font-weight: 500;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
}
.calculation .profit-calculator .part-amount form input {
  width: 100%;
  height: 40px;
  padding: 0 20px 0 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #555;
}
.calculation .profit-calculator .part-amount form input:focus {
  color: #888;
}
.calculation .profit-calculator .part-amount form button.dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  background: none;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 0 4px 4px 0;
  font-family: "Yantramanav", sans-serif;
  font-size: 16px;
  border-left: 1px solid #ccc;
  color: #555;
  color: #8e54e9;
  font-weight: 500;
}
.calculation .profit-calculator .part-amount form button.dropdown-toggle:after {
  border-top-color: #8e54e9;
  border-bottom-color: #8e54e9;
}
.calculation .profit-calculator .part-amount form .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #49596d;
}
.calculation .profit-calculator .part-amount form .dropdown-item:active, .calculation .profit-calculator .part-amount form .dropdown-item.active {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.calculation .profit-calculator .cursor-not-allowed {
  position: relative;
  z-index: 1;
  cursor: alias;
}
.calculation .profit-calculator .fa-check {
  margin-left: 10px;
  color: #499a57;
  opacity: 0;
  transition: all 0.3s ease-in;
  visibility: hidden;
  position: relative;
  z-index: 5;
}
.calculation .profit-calculator .calculate-all {
  width: 140px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: 0;
  color: #fff;
  font-family: "Yantramanav", sans-serif;
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-transform: capitalize;
}
.calculation .profit-calculator .calculate-all.calculated {
  pointer-events: none;
}
.calculation .profit-calculator .calculate-all.calculated:after {
  opacity: 1;
}
.calculation .profit-calculator .calculate-all:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #499a57;
  z-index: -1;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.calculation .profit-calculator .part-result {
  position: relative;
  z-index: 2;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  padding: 30px;
}
.calculation .profit-calculator .part-result:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -1;
}
.calculation .profit-calculator .part-result ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.calculation .profit-calculator .part-result ul li {
  font-family: "Yantramanav", sans-serif;
  text-align: left;
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  border-left: 1px solid rgba(221, 221, 221, 0.2);
  border-bottom: 1px solid rgba(221, 221, 221, 0.2);
  justify-content: center;
}
.calculation .profit-calculator .part-result ul li:nth-child(1), .calculation .profit-calculator .part-result ul li:nth-child(3) {
  border-left: none;
  justify-content: flex-start;
}
.calculation .profit-calculator .part-result ul li:nth-child(3), .calculation .profit-calculator .part-result ul li:nth-child(4) {
  border-bottom: none;
  padding-top: 20px;
}
.calculation .profit-calculator .part-result ul li:nth-child(3) .text .number, .calculation .profit-calculator .part-result ul li:nth-child(4) .text .number {
  margin-bottom: -10px;
}
.calculation .profit-calculator .part-result ul li:nth-child(2), .calculation .profit-calculator .part-result ul li:nth-child(4) {
  justify-content: flex-end;
}
.calculation .profit-calculator .part-result ul li:nth-child(1), .calculation .profit-calculator .part-result ul li:nth-child(2) {
  padding-bottom: 11px;
}
.calculation .profit-calculator .part-result ul li:nth-child(1) .text .title, .calculation .profit-calculator .part-result ul li:nth-child(2) .text .title {
  margin-top: -3px;
}
.calculation .profit-calculator .part-result ul li .icon {
  width: 35px;
  margin-right: 10px;
}
.calculation .profit-calculator .part-result ul li span {
  display: block;
}
.calculation .profit-calculator .part-result ul li span.title {
  font-size: 16px;
  line-height: 20px;
  color: #ddd;
  margin-top: -3px;
}
.calculation .profit-calculator .part-result ul li span.title br {
  display: none;
}
.calculation .profit-calculator .part-result ul li span.number {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.login {
  padding: 120px 0;
  background: #fff;
}
.login .form-area {
  box-shadow: 0px 0px 20px 0px rgba(33, 33, 33, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.login .form-area .login-form {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 40px;
}
.login .form-area .login-form form .form-group {
  margin-bottom: 24px;
}
.login .form-area .login-form form .form-group label {
  font-size: 15px;
  line-height: 100%;
  color: #fff;
  margin-bottom: 13px;
  text-transform: capitalize;
}
.login .form-area .login-form form .form-group input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: 1px solid #ffffffb3;
  color: #fff;
}
.login .form-area .login-form form .form-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-top: 34px;
}
.login .form-area .login-form form .form-check .btn-form {
  background: #fff;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  margin: 5px 0;
  padding: 0 40px;
  height: 40px;
  line-height: 36px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
}
.login .form-area .login-form form .form-check [type=checkbox] {
  opacity: 0;
  margin: 0;
}
.login .form-area .login-form form .form-check [type=checkbox]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.login .form-area .login-form form .form-check [type=checkbox]:checked + label:before, .login .form-area .login-form form .form-check [type=checkbox]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 2px solid #8e54e9;
  border-radius: 100%;
  background: #fff;
  margin-left: -2px;
}
.login .form-area .login-form form .form-check [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.login .form-area .login-form form .form-check [type=checkbox]:checked + label:after, .login .form-area .login-form form .form-check [type=checkbox]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
  margin-left: -2px;
}
.login .form-area .login-form form .form-check label {
  color: #fff;
  font-weight: 400;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  padding-left: 5px;
  margin: 0;
}
.login .form-area .login-form .other-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 23px;
}
.login .form-area .login-form .other-option a {
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
  text-decoration: underline;
}
.login .form-area .login-form .other-option a:last-child {
  color: #ffffffcc;
  text-decoration: none;
}
.login .form-area .login-form .login-by-social {
  padding-top: 10px;
}
.login .form-area .login-form .login-by-social a {
  display: block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #fff;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  overflow: hidden;
}
.login .form-area .login-form .login-by-social a i {
  float: left;
  line-height: 40px;
  width: 40px;
  color: #fff;
  font-size: 14px;
}
.login .form-area .login-form .login-by-social a.login-fb {
  color: #3b5999;
  margin-bottom: 30px;
}
.login .form-area .login-form .login-by-social a.login-fb i {
  background: #3b5999;
}
.login .form-area .login-form .login-by-social a.login-gl {
  color: #dd4b39;
}
.login .form-area .login-form .login-by-social a.login-gl i {
  background: #dd4b39;
}
.login .form-area .blank-space {
  background: url(../img/login-bg.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.login .form-area .blank-space:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #212121;
  opacity: 0.1;
}

.error {
  padding: 120px 0;
  background: #fff;
}
.error .part-text {
  text-align: center;
  margin-top: 40px;
}
.error .part-text h4 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #383838;
  margin-bottom: 0;
}
.error .part-text .back-to-home-btn {
  display: inline-block;
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
  font-weight: 500;
  margin-top: 40px;
  box-shadow: 0px 5px 5px 0px rgba(33, 33, 33, 0.2);
}

.affiliate {
  padding: 120px 0;
}
.affiliate .part-para {
  padding-right: 60px;
}
.affiliate .part-para .section-title {
  text-align: left;
  margin-bottom: 19px;
}
.affiliate .part-para .section-title span.sub-title {
  margin-bottom: 6px;
}
.affiliate .part-para .section-title h2 {
  font-size: 40px;
  line-height: 50px;
}
.affiliate .part-para h2.title {
  font-size: 40px;
  line-height: 50px;
  color: #49596d;
  font-weight: 900;
  text-transform: capitalize;
  margin-top: -10px;
  margin-bottom: 20px;
}
.affiliate .part-para p {
  font-size: 16px;
  line-height: 28px;
  color: #555;
}
.affiliate .part-para a.btn-hyipox-2 {
  margin-top: 16px;
}
.affiliate .part-para .text-img {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
}
.affiliate .part-affilate-feature .single-feature {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-bottom: 30px;
}
.affiliate .part-affilate-feature .single-feature:last-child {
  margin-bottom: 0;
}
.affiliate .part-affilate-feature .single-feature .part-icon {
  width: 60px;
  margin-right: 30px;
}
.affiliate .part-affilate-feature .single-feature .part-icon img {
  width: 100%;
}
.affiliate .part-affilate-feature .single-feature .part-text h3.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #49596d;
  margin-bottom: 8px;
}
.affiliate .part-affilate-feature .single-feature .part-text p {
  font-size: 16px;
  line-height: 28px;
  color: #555;
  margin-bottom: 0;
}
.affiliate .affiliate-how-to-do {
  padding: 40px;
  text-align: center;
  padding-bottom: 38px;
  margin-top: 47px;
  margin-bottom: 60px;
  position: relative;
  z-index: 2;
}
.affiliate .affiliate-how-to-do:after {
  position: absolute;
  content: "";
  left: -60px;
  top: 0;
  right: -60px;
  bottom: 0;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -1;
  border-radius: 10px;
}
.affiliate .affiliate-how-to-do h2.title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 12px;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 0.5px;
  margin-top: -10px;
  color: #fff;
}
.affiliate .affiliate-how-to-do .single-affiliate {
  display: inline-block;
  margin-right: 60px;
  text-align: center;
  display: inline-block;
}
.affiliate .affiliate-how-to-do .single-affiliate:last-child {
  margin-right: 0;
}
.affiliate .affiliate-how-to-do .single-affiliate .part-icon {
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  display: inline-block;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  margin-bottom: 26px;
  position: relative;
}
.affiliate .affiliate-how-to-do .single-affiliate .part-icon .sr-num {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #4776e6;
  border: 2px solid #fff;
  text-align: center;
  line-height: 26px;
  position: absolute;
  top: 0px;
  right: 0px;
  background: #fff;
  color: #555;
  font-weight: 600;
}
.affiliate .affiliate-how-to-do .single-affiliate .part-icon img {
  width: 40px;
  filter: brightness(100);
}
.affiliate .affiliate-how-to-do .single-affiliate .part-text p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #fff;
}

.refferal-commision {
  padding: 120px 0;
  background: url("../img/feature-bg.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
}
.refferal-commision:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: -1;
  opacity: 0.35;
}
.refferal-commision .single-commision {
  text-align: center;
}
.refferal-commision .single-commision .part-icon {
  height: 80px;
  display: inline-block;
  margin-bottom: 25px;
}
.refferal-commision .single-commision .part-icon img {
  width: auto;
  max-width: none;
  height: 100%;
}
.refferal-commision .single-commision .part-text span.title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #49596d;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .refferal-commision {
    padding: 60px 0 30px;
  }

  .refferal-commision .single-commision {
    margin-bottom: 30px;
  }

  .refferal-commision .single-commision .part-icon {
    height: 60px;
  }

  .refferal-commision .single-commision .part-text span.title {
    font-size: 20px;
    line-height: 30px;
  }

  .affiliate .part-para .section-title h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .affiliate .part-para {
    padding-right: 0;
  }

  .affiliate .part-affilate-feature .single-feature .part-text h3.title {
    font-size: 20px;
    line-height: 28px;
  }

  .part-affilate-feature {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 575px) {
  .affiliate .affiliate-how-to-do .single-affiliate {
    margin-right: 0;
    margin-bottom: 20px;
    display: block;
  }

  .affiliate .affiliate-how-to-do {
    padding-bottom: 10px;
    padding-top: 50px;
  }

  .affiliate .affiliate-how-to-do h2.title {
    font-size: 28px;
    line-height: 38px;
  }

  .affiliate .affiliate-how-to-do .single-affiliate .part-text p {
    font-size: 16px;
  }

  .affiliate {
    padding: 60px 0;
  }

  .affiliate .part-para h2.title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px) {
  .affiliate .affiliate-how-to-do .single-affiliate {
    margin-right: 0;
    margin: 0 20px;
    margin-bottom: 20px;
  }

  .affiliate .affiliate-how-to-do {
    padding-bottom: 10px;
    padding-top: 50px;
  }

  .affiliate .affiliate-how-to-do h2.title {
    font-size: 28px;
    line-height: 38px;
  }

  .affiliate .affiliate-how-to-do .single-affiliate .part-text p {
    font-size: 16px;
  }

  .affiliate {
    padding: 60px 0;
  }

  .affiliate .part-para h2.title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .refferal-commision {
    padding: 60px 0 30px;
  }

  .refferal-commision .single-commision {
    margin-bottom: 30px;
  }

  .refferal-commision .single-commision .part-icon {
    height: 60px;
  }

  .refferal-commision .single-commision .part-text span.title {
    font-size: 20px;
    line-height: 30px;
  }

  .part-affilate-feature {
    margin-top: 10px;
  }
}
.term {
  padding: 120px 0;
  background: #fff;
}
.term h1.title {
  font-size: 36px;
  line-height: 46px;
  color: #444;
  margin-top: -5px;
}
.term h2 {
  font-size: 24px;
  line-height: 34px;
  color: #555;
  font-weight: 600;
  margin-bottom: 13px;
  margin-top: 32px;
}
.term p {
  color: #666;
  line-height: 28px;
  text-transform: lowercase;
}
.term p:first-letter {
  text-transform: uppercase;
}
.term p.date {
  color: green;
  font-weight: 500;
  font-size: 18px;
}
.term ul {
  margin-bottom: 16px;
}
.term ul li {
  padding-bottom: 6px;
}
.term ul li:last {
  padding-bottom: none;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .term {
    padding: 60px 0;
  }

  .term h1.title {
    font-size: 28px;
    line-height: 38px;
  }

  .term h2 {
    font-size: 22px;
    line-height: 32px;
  }
}
.user-dashboard {
  padding: 120px 0;
  background: #fff;
}
@media (min-width: 320px) and (max-width: 991px) {
  .user-dashboard {
    padding: 60px 0;
  }
}
@media (max-width: 479px) {
  .user-dashboard {
    padding-bottom: 50px;
  }
}
.user-dashboard .dashboard-menu nav {
  padding: 0;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(73, 89, 109, 0.3);
}
.user-dashboard .dashboard-menu nav ul {
  display: flex;
}
@media (min-width: 320px) and (max-width: 991px) {
  .user-dashboard .dashboard-menu nav ul {
    display: block;
  }
  .user-dashboard .dashboard-menu nav ul .nav-item {
    display: inline-block;
  }
  .user-dashboard .dashboard-menu nav ul .nav-item a.nav-link {
    padding: 7px 12px;
    margin: 0 4px 8px;
  }
}
.user-dashboard .dashboard-menu nav ul .nav-item .nav-link {
  padding: 10px 20px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  position: relative;
  z-index: 2;
  color: #49596d;
  margin: 0 16px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s ease-in;
}
.user-dashboard .dashboard-menu nav ul .nav-item .nav-link:after {
  position: absolute;
  content: "";
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background: #fff;
  border-radius: 4px;
  z-index: -1;
  opacity: 1;
  transition: all 0.3s ease-in;
}
.user-dashboard .dashboard-menu nav ul .nav-item .nav-link:hover, .user-dashboard .dashboard-menu nav ul .nav-item .nav-link.active {
  color: #fff;
}
.user-dashboard .dashboard-menu nav ul .nav-item .nav-link:hover:after, .user-dashboard .dashboard-menu nav ul .nav-item .nav-link.active:after {
  opacity: 0;
}
.user-dashboard .dashboard-menu nav ul .nav-item:first-child .nav-link {
  margin-left: 0;
}
.user-dashboard .user-statics {
  margin-bottom: 10px;
}
@media (max-width: 479px) {
  .user-dashboard .user-statics {
    margin-bottom: 20px;
  }
}
.user-dashboard .user-statics .single-statics {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 6px;
  padding: 30px;
}
@media (min-width: 320px) and (max-width: 991px) {
  .user-dashboard .user-statics .single-statics {
    padding: 20px;
  }
}
@media (max-width: 479px) {
  .user-dashboard .user-statics .single-statics {
    margin-bottom: 15px;
  }
}
.user-dashboard .user-statics .single-statics.refferal-page-static {
  position: relative;
}
.user-dashboard .user-statics .single-statics.refferal-page-static:after {
  position: absolute;
  content: "";
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -41px;
  border: 2px dashed #888;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}
@media (max-width: 1199px) {
  .user-dashboard .user-statics .single-statics.refferal-page-static:after {
    display: none;
  }
}
.user-dashboard .user-statics .single-statics .part-icon {
  width: 60px;
  margin-right: 20px;
}
.user-dashboard .user-statics .single-statics .part-icon img {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 100%;
}
.user-dashboard .user-statics .single-statics .part-info span {
  display: block;
}
.user-dashboard .user-statics .single-statics .part-info span.number {
  font-size: 22px;
  line-height: 32px;
  font-family: "Yantramanav", sans-serif;
  font-weight: 500;
  color: #fff;
  margin-top: -4px;
  margin-bottom: 6px;
}
@media (max-width: 479px) {
  .user-dashboard .user-statics .single-statics .part-info span.number {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
  }
}
.user-dashboard .user-statics .single-statics .part-info span.text {
  font-size: 18px;
  font-weight: 400;
  font-family: "Yantramanav", sans-serif;
  color: #fff;
  margin-bottom: -3px;
}
@media (max-width: 479px) {
  .user-dashboard .user-statics .single-statics .part-info span.text {
    font-size: 17px;
  }
}
.user-dashboard h3.title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 900;
  color: #49596d;
  border-bottom: 1px solid rgba(71, 118, 230, 0.6);
  margin-bottom: 22px;
  padding-bottom: 19px;
  margin-top: -5px;
  letter-spacing: 0.5px;
}
@media (max-width: 479px) {
  .user-dashboard h3.title {
    font-size: 20px;
    line-height: 30px;
  }
}
.user-dashboard .user-benmathew {
  padding: 20px 30px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid rgba(123, 94, 233, 0.3);
}
.user-dashboard .user-benmathew .single-data {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}
.user-dashboard .user-benmathew .single-data:last-child {
  margin-bottom: 0;
}
.user-dashboard .user-benmathew .single-data:after {
  position: absolute;
  content: "";
  left: 0;
  top: 1px;
  width: 10px;
  height: 10px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
  margin-top: 8px;
}
.user-dashboard .user-benmathew .single-data:before {
  width: 16px;
  height: 16px;
  position: absolute;
  content: "";
  left: -3px;
  top: -2px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  opacity: 0.2;
  margin-top: 8px;
  border-radius: 50%;
}
.user-dashboard .user-benmathew .single-data span {
  display: block;
}
.user-dashboard .user-benmathew .single-data span.title {
  font-size: 14px;
  line-height: 28px;
  color: #49596d;
  font-weight: 600;
  margin-bottom: 3px;
}
.user-dashboard .user-benmathew .single-data span.data {
  font-size: 15px;
}
.user-dashboard .transaction-chart {
  background: #fff;
  border-radius: 6px;
}
@media (min-width: 320px) and (max-width: 991px) {
  .user-dashboard .transaction-chart {
    display: none;
  }
}
.user-dashboard .transaction-chart h3.title {
  text-align: center;
}
.user-dashboard .transactions-table {
  margin-top: 40px;
  border-radius: 6px;
  background: #fff;
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_length label {
  color: #49596d;
  text-transform: capitalize;
  margin-bottom: 30px;
}
@media (max-width: 640px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_length label {
    margin-bottom: 10px;
  }
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_length label select {
  margin: 0 10px 0 10px;
  color: #4776e6;
  border-color: red;
  border: 1px solid #4776e6;
  padding: 2px;
  height: 40px;
  width: 80px;
  -webkit-appearance: none;
          appearance: none;
  padding-left: 15px;
  z-index: 2;
  cursor: pointer;
  border-radius: 6px;
  height: 35px;
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_length label i {
  background: red;
  color: #fff;
  height: 40px;
  line-height: 38px;
  margin-right: -20px;
  padding-right: 18px;
  position: relative;
  left: -30px;
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_filter label {
  color: #49596d;
  text-transform: capitalize;
  margin-bottom: 30px;
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_filter label input {
  height: 40px;
  border: 1px solid #4776e6;
  background: transparent;
  color: #49596d;
  padding: 0 25px;
  margin-left: 15px;
  transition: all 0.3s ease-in;
  border-radius: 6px;
}
@media (min-width: 320px) and (max-width: 991px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_filter label input {
    width: 215px;
    height: 35px;
  }
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_filter label input:focus {
  border-color: #4776e6;
}
@media (max-width: 991px) {
  .user-dashboard .transactions-table table {
    width: 1110px !important;
  }
}
.user-dashboard .transactions-table table.dataTable.display tbody tr.even > .sorting_1, .user-dashboard .transactions-table table.dataTable.display tbody tr.odd > .sorting_1 {
  background: #fff;
}
.user-dashboard .transactions-table table.dataTable.display tbody tr {
  background: #fff;
}
.user-dashboard .transactions-table table.dataTable.display tbody tr:first-child td:first-child {
  border-top: 1px solid #fff;
}
.user-dashboard .transactions-table table.dataTable.display tbody tr:last-child td:first-child {
  border-radius: 0 0 0 6px;
  border-bottom: 1px solid rgba(73, 89, 109, 0.3);
}
.user-dashboard .transactions-table table.dataTable.display tbody tr:last-child td:last-child {
  border-radius: 0 0 6px 0;
}
.user-dashboard .transactions-table table.dataTable.display tbody td {
  border: none;
  border-bottom: 1px solid rgba(73, 89, 109, 0.3);
  color: #49596d;
  font-weight: 500;
  font-size: 15px;
  height: 55px;
  padding: 0;
  padding-left: 20px;
}
@media (max-width: 479px) {
  .user-dashboard .transactions-table table.dataTable.display tbody td {
    height: 45px;
  }
}
.user-dashboard .transactions-table table.dataTable.display tbody td:first-child {
  padding-left: 30px;
  border-left: 1px solid rgba(73, 89, 109, 0.3);
  font-weight: 500;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9) !important;
  color: #fff;
  border-bottom: 1px solid #ffffff;
}
.user-dashboard .transactions-table table.dataTable.display tbody td:last-child {
  border-right: 1px solid rgba(73, 89, 109, 0.3);
}
.user-dashboard .transactions-table table.dataTable thead {
  margin-top: 30px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
.user-dashboard .transactions-table table.dataTable thead th {
  padding: 0;
  padding: 0 20px;
  height: 60px;
  color: #fff;
  font-weight: 500;
  border-bottom: 1px solid rgba(73, 89, 109, 0.3);
  border-top: 1px solid rgba(73, 89, 109, 0.3);
  border-left: none;
}
@media (max-width: 479px) {
  .user-dashboard .transactions-table table.dataTable thead th {
    height: 45px;
  }
}
.user-dashboard .transactions-table table.dataTable thead th:first-child {
  padding-left: 30px;
  border-left: 1px solid rgba(73, 89, 109, 0.3);
  border-radius: 6px 0 0 0;
}
.user-dashboard .transactions-table table.dataTable thead th:last-child {
  border-right: 1px solid rgba(73, 89, 109, 0.3);
  border-radius: 0 6px 0 0;
}
.user-dashboard .transactions-table tfoot {
  background: #111;
  color: #fff;
  display: none;
}
.user-dashboard .transactions-table tfoot tr th {
  font-weight: 400;
  padding: 0 20px;
  height: 40px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.user-dashboard .transactions-table tfoot tr th:first-child {
  padding-left: 30px;
}
.user-dashboard .transactions-table tfoot tr th:last-child {
  border-right: none;
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 1px solid #4776e6;
  color: #4776e6 !important;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  margin-right: 10px;
  transition: all 0.3s ease-in;
  border-radius: 6px;
}
@media (max-width: 607px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate .paginate_button {
    margin-bottom: 10px;
  }
}
@media (max-width: 479px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate .paginate_button {
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
  }
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate .paginate_button.current, .user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-color: transparent;
  color: #fff !important;
}
.user-dashboard .transactions-table .paginate_button.next {
  margin-right: 0 !important;
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate {
  padding-top: 30px;
}
@media (max-width: 901px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
    padding-top: 15px;
  }
}
.user-dashboard .transactions-table .dataTables_wrapper .dataTables_info {
  color: #49596d;
  line-height: 40px;
  padding-top: 30px;
  padding-left: 30px;
  font-weight: 400;
}
@media (max-width: 901px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_info {
    float: none;
    text-align: center;
  }
}
@media (max-width: 479px) {
  .user-dashboard .transactions-table .dataTables_wrapper .dataTables_info {
    padding-left: 0;
  }
}
.user-dashboard .single-plan {
  background: url("../img/price-plan-bg-1.jpg") center center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 28px 0;
  border-radius: 8px;
  margin-bottom: 30px;
}
@media (min-width: 480px) and (max-width: 575px) {
  .user-dashboard .single-plan {
    width: 240px;
    max-width: 240px;
    display: inline-block;
  }
}
.user-dashboard .single-plan:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -2;
  opacity: 0.95;
  border-radius: 7px;
}
.user-dashboard .single-plan:before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  background: #fff;
  z-index: -1;
  opacity: 1;
  border-radius: 8px;
}
.user-dashboard .single-plan.active:after {
  background: linear-gradient(-19deg, #1c9864 10%, #54e959);
}
.user-dashboard .single-plan.active:before {
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}
.user-dashboard .single-plan.active .price-button {
  background: linear-gradient(-19deg, #1c9864 10%, #54e959);
}
.user-dashboard .single-plan h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #49596d;
  margin-bottom: 21px;
  transition: all 0.3s ease-in;
}
.user-dashboard .single-plan .plan-icon {
  height: 70px;
  margin-bottom: 19px;
}
.user-dashboard .single-plan .plan-icon img {
  height: 100%;
}
.user-dashboard .single-plan .plan-icon svg {
  width: 60px;
  display: inline-block;
  background: #73af55;
  border-radius: 50%;
  padding: 5px;
}
.user-dashboard .single-plan .plan-icon .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  stroke: #fff;
}
.user-dashboard .single-plan .plan-icon .path.circle {
  animation: dash 0.9s ease-in-out;
}
.user-dashboard .single-plan .plan-icon .path.line {
  stroke-dashoffset: 1000;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.user-dashboard .single-plan .plan-icon .path.check {
  stroke-dashoffset: -100;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.user-dashboard .single-plan .price-info {
  margin-bottom: 27px;
}
.user-dashboard .single-plan .price-info span {
  display: block;
}
.user-dashboard .single-plan .price-info span.parcent {
  font-size: 40px;
  font-weight: 400;
  background: -webkit-linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 4px;
  margin-bottom: -4px;
  font-family: "Kalam", cursive;
}
.user-dashboard .single-plan .price-info span.price {
  font-size: 14px;
  font-weight: 600;
  color: #49596d;
}
.user-dashboard .single-plan .price-info span.price small {
  font-size: 16px;
  font-weight: 500;
}
.user-dashboard .single-plan .price-button {
  height: 40px;
  line-height: 40px;
}
.user-dashboard .single-plan .price-button:after {
  transition: all 0.3s ease-in;
}
.user-dashboard .single-plan .price-button:hover:after {
  opacity: 0;
}
.user-dashboard .select-plan {
  margin-bottom: 8px;
}
@media (min-width: 480px) and (max-width: 575px) {
  .user-dashboard .select-plan {
    text-align: center;
  }
}
.user-dashboard .last-step {
  border: 1px solid #ccc;
  padding: 30px;
  border-radius: 6px;
}
@media (max-width: 479px) {
  .user-dashboard .last-step {
    padding: 20px;
  }
}
@media (max-width: 1199px) {
  .user-dashboard .last-step .payment-method-list .oit-width-1 {
    width: 120%;
  }
}
.user-dashboard .last-step label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #949494;
}
.user-dashboard .last-step .withdraw-btn {
  margin-top: 15px;
  height: 40px;
  line-height: 40px;
}
.user-dashboard .last-step form {
  position: relative;
}
.user-dashboard .last-step form .currency-symbol {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 0;
  top: 0;
  line-height: 50px;
  text-align: center;
  border-right: 1px solid #ccc;
  color: #8e54e9;
  font-weight: 500;
  font-family: "Yantramanav", sans-serif;
  font-size: 20px;
}
@media (max-width: 479px) {
  .user-dashboard .last-step form .currency-symbol {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
.user-dashboard .last-step form input {
  width: 100%;
  height: 50px;
  padding: 0 20px 0 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}
@media (max-width: 479px) {
  .user-dashboard .last-step form input {
    height: 40px;
    padding: 0 20px 0 50px;
    font-size: 16px;
  }
}
.user-dashboard .last-step form input:focus {
  color: #888;
}
.user-dashboard .last-step form button.dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  background: none;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 0 4px 4px 0;
  font-family: "Yantramanav", sans-serif;
  font-size: 16px;
  border-left: 1px solid #ccc;
  color: #555;
  color: #8e54e9;
  font-weight: 500;
}
@media (max-width: 479px) {
  .user-dashboard .last-step form button.dropdown-toggle {
    height: 40px;
    padding: 0 15px;
  }
}
.user-dashboard .last-step form button.dropdown-toggle:after {
  border-top-color: #8e54e9;
  border-bottom-color: #8e54e9;
}
.user-dashboard .last-step form .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #49596d;
}
.user-dashboard .last-step form .dropdown-item:active, .user-dashboard .last-step form .dropdown-item.active {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.user-dashboard .last-step .dropdown a.dropdown-toggle {
  border: 1px solid #ccc;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #888;
  padding-left: 60px;
  position: relative;
}
.user-dashboard .last-step .dropdown a.dropdown-toggle .payment-method-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 49px;
  width: 49px;
  border-right: 1px solid #ccc;
  text-align: center;
  top: 0;
  left: 0;
}
.user-dashboard .last-step .dropdown a.dropdown-toggle .payment-method-icon img {
  width: 18px;
}
@media (max-width: 479px) {
  .user-dashboard .last-step .dropdown a.dropdown-toggle .payment-method-icon {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 479px) {
  .user-dashboard .last-step .dropdown a.dropdown-toggle {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 10px;
    padding-left: 55px;
  }
}
.user-dashboard .last-step .dropdown a.dropdown-toggle:focus {
  color: #555;
}
.user-dashboard .last-step .dropdown .dropdown-item {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  color: #49596d;
}
.user-dashboard .last-step .dropdown .dropdown-item:active, .user-dashboard .last-step .dropdown .dropdown-item.active {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
}
.user-dashboard .last-step .calculation-content {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px dashed #888;
}
.user-dashboard .last-step .calculation-content h4.title {
  font-size: 22px;
  line-height: 32px;
  text-transform: capitalize;
  margin: -7px 0 19px;
}
@media (max-width: 479px) {
  .user-dashboard .last-step .calculation-content h4.title {
    font-size: 18px;
    line-height: 28px;
  }
}
.user-dashboard .last-step .calculation-content ul li {
  border-bottom: 1px dashed #ccc;
  padding: 15px 0;
}
@media (max-width: 479px) {
  .user-dashboard .last-step .calculation-content ul li {
    font-size: 15px;
  }
}
.user-dashboard .last-step .calculation-content ul li i {
  margin-right: 10px;
  background: linear-gradient(-19deg, #1c9864 10%, #54e959);
  color: #fff;
  padding: 3px;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .user-dashboard .last-step .calculation-content ul li i {
    display: none;
  }
}
@media (max-width: 767px) {
  .user-dashboard .last-step .calculation-content ul li i {
    display: inline-block;
  }
}
@media (max-width: 479px) {
  .user-dashboard .last-step .calculation-content ul li i {
    margin-right: 5px;
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
  }
}
.user-dashboard .last-step .calculation-content ul li span {
  display: inline-block;
  width: 45%;
  color: #888;
}
.user-dashboard .last-step .calculation-content ul li span.list-title {
  position: relative;
}
.user-dashboard .last-step .calculation-content ul li span.list-title:after {
  position: absolute;
  content: "";
  right: 10px;
  top: 50%;
  width: 30px;
  background: #ccc;
  height: 1px;
}
@media (max-width: 479px) {
  .user-dashboard .last-step .calculation-content ul li span.list-title:after {
    display: none;
  }
}
.user-dashboard .last-step .calculation-content ul li span.list-descr {
  font-weight: 500;
}
@media (max-width: 479px) {
  .user-dashboard .last-step .calculation-content ul li span.list-descr {
    width: 43%;
  }
}
@media (max-width: 575px) {
  .user-dashboard .last-step .calculation-content ul li span.list-descr {
    width: 43%;
  }
}
.user-dashboard .last-step .calculation-content ul li:first-child {
  border-top: 1px dashed #ccc;
}
.user-dashboard .finish-button {
  text-align: right;
  margin-top: 30px;
}
@media (max-width: 479px) {
  .user-dashboard .finish-button .btn-hyipox-2 {
    height: 40px;
    line-height: 40px;
  }
}
.user-dashboard .available-balance-el {
  padding-left: 30px;
}
@media (max-width: 991px) {
  .user-dashboard .available-balance-el {
    padding-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 1199px) {
  .user-dashboard .available-balance-el {
    padding-left: 0;
    margin-left: 22%;
  }
}
.user-dashboard .available-balance-el span.title {
  font-size: 16px;
  line-height: 26px;
  color: #49596d;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 14px;
  font-weight: 500;
  display: inline-block;
  font-family: "Yantramanav", sans-serif;
}
.user-dashboard .available-balance-el span.title:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #49596d;
}
.user-dashboard .available-balance-el ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
.user-dashboard .available-balance-el ul li:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50%;
}
.user-dashboard .available-balance-el ul li span {
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  color: #49596d;
  font-family: "Yantramanav", sans-serif;
}
.user-dashboard .available-balance-el ul li span.text {
  width: 130px;
}
.user-dashboard .available-balance-el ul li span.amount {
  font-weight: 500;
}
.user-dashboard .available-balance-el ul li.pending span.amount {
  color: red;
}
.user-dashboard .available-balance-el ul li.available span.amount {
  color: green;
}
@media (max-width: 767px) {
  .user-dashboard .add-credit-card {
    margin-bottom: 30px;
  }
}
.user-dashboard .add-credit-card .expire-date-elem .form-group {
  margin-bottom: 0;
}
.user-dashboard .add-credit-card .btn-add-new-card {
  border: none;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
}
.user-dashboard .add-credit-card .card-body {
  padding: 30px;
}
@media (max-width: 479px) {
  .user-dashboard .add-credit-card .card-body {
    padding: 20px;
  }
}
.user-dashboard .add-credit-card .card-body label {
  font-size: 14px;
  color: #949494;
}
.user-dashboard .add-credit-card .card-body input {
  font-family: "Yantramanav", sans-serif;
  color: #888;
}
.user-dashboard .history-search-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 479px) {
  .user-dashboard .history-search-filter {
    display: block;
  }
  .user-dashboard .history-search-filter .payment-process .dropdown .btn, .user-dashboard .history-search-filter .transaction-process .dropdown .btn {
    width: 100%;
    font-size: 15px;
    height: 40px;
    text-align: left;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .user-dashboard .history-search-filter {
    display: block;
  }
  .user-dashboard .history-search-filter .payment-process .dropdown .btn, .user-dashboard .history-search-filter .transaction-process .dropdown .btn {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .user-dashboard .history-search-filter {
    display: block;
    text-align: center;
  }
}
.user-dashboard .history-search-filter .date-select {
  margin-right: 20px;
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .user-dashboard .history-search-filter .date-select {
    width: 220px;
    display: inline-block;
    margin-bottom: 20px;
  }
}
.user-dashboard .history-search-filter .date-select input {
  font-weight: 500;
  background: transparent;
  padding: 0 20px;
  height: 50px;
  color: #49596d;
  border-color: #6e63e7;
}
@media (max-width: 479px) {
  .user-dashboard .history-search-filter .date-select input {
    height: 40px;
    font-size: 15px;
  }
}
.user-dashboard .history-search-filter .dbOpenButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: none;
}
.user-dashboard .history-search-filter .payment-process, .user-dashboard .history-search-filter .transaction-process {
  margin-right: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .user-dashboard .history-search-filter .payment-process, .user-dashboard .history-search-filter .transaction-process {
    display: inline-block;
  }
}
.user-dashboard .history-search-filter .payment-process .dropdown button, .user-dashboard .history-search-filter .transaction-process .dropdown button {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #49596d;
  padding: 0;
  height: 50px;
  padding: 0 30px;
  position: relative;
  font-weight: 500;
  z-index: 2;
}
.user-dashboard .history-search-filter .payment-process .dropdown button:before, .user-dashboard .history-search-filter .transaction-process .dropdown button:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 3px;
  z-index: -1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .user-dashboard .history-search-filter .filter-btn {
    display: inline-block;
  }
}
.user-dashboard .history-search-filter .filter-btn .btn-hyipox-2 {
  border: none;
  cursor: pointer;
}
@media (max-width: 479px) {
  .user-dashboard .history-search-filter .filter-btn .btn-hyipox-2 {
    height: 40px;
    line-height: 40px;
  }
}
.user-dashboard .refferal-info {
  border: 2px dashed #888;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .user-dashboard .refferal-info {
    padding-bottom: 10px;
  }
}
.user-dashboard .refferal-info:after {
  position: absolute;
  content: "";
  left: calc(50% + 1px);
  top: -3px;
  width: 298px;
  height: 5px;
  background: #fff;
}
@media (max-width: 1199px) {
  .user-dashboard .refferal-info:after {
    display: none;
  }
}
.user-dashboard .refferal-info .single-info {
  text-align: center;
}
@media (max-width: 991px) {
  .user-dashboard .refferal-info .single-info {
    margin-bottom: 20px;
  }
}
.user-dashboard .refferal-info .single-info .part-text span {
  display: block;
}
.user-dashboard .refferal-info .single-info .part-text span.title {
  color: #49596d;
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .user-dashboard .refferal-info .single-info .part-text span.title {
    margin-bottom: 6px;
  }
}
.user-dashboard .refferal-info .single-info .part-text span.descr {
  background-image: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Yantramanav", sans-serif;
  font-size: 22px;
  font-weight: 700;
}
@media (max-width: 575px) {
  .user-dashboard .ref-link h3.title {
    margin-bottom: 17px;
    padding-bottom: 9px;
  }
}
@media (max-width: 767px) {
  .user-dashboard .ref-link {
    margin-bottom: 30px;
  }
}
.user-dashboard .ref-link form {
  position: relative;
  padding: 2px;
}
.user-dashboard .ref-link form:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  z-index: -2;
  border-radius: 50px;
}
.user-dashboard .ref-link form input {
  width: 100%;
  height: 45px;
  padding: 0 120px 0 30px;
  border-radius: 50px;
  font-family: "Yantramanav", sans-serif;
  border: 1px solid #888;
}
.user-dashboard .ref-link form button {
  height: 45px;
  padding: 0 20px;
  border-radius: 50px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: "Yantramanav", sans-serif;
  position: absolute;
  top: 2px;
  right: 0px;
  font-family: "Yantramanav", sans-serif;
}
.user-dashboard .ref-link form button span {
  display: inline-block;
}
.user-dashboard .ref-link form button span.icon {
  margin-right: 5px;
}
@media (max-width: 575px) {
  .user-dashboard .share-on-social h3.title {
    margin-bottom: 17px;
    padding-bottom: 9px;
  }
}
.user-dashboard .share-on-social ul li {
  display: inline-block;
}
.user-dashboard .share-on-social ul li a {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  margin-right: 6px;
}
@media (max-width: 575px) {
  .user-dashboard .share-on-social ul li a {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 13px;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.user-panel-title {
  margin-bottom: 36px;
}
.user-panel-title h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  padding-left: 20px;
}
.user-panel-title h3:after {
  position: absolute;
  content: "";
  left: 100%;
  top: 50%;
  width: 150px;
  height: 2px;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  transform: translateY(-50%);
}
.user-panel-title h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
}

/*==========================================
    player profile
==========================================*/
.profile .accont-tab {
  margin-top: 75px;
  margin-right: 30px;
}
@media (max-width: 991px) {
  .profile .accont-tab {
    margin-top: 0;
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .profile .accont-tab .nav {
    flex-direction: row !important;
  }
}
.profile .accont-tab .nav .nav-link {
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
  margin-bottom: 10px;
}
@media (min-width: 576px) and (max-width: 991px) {
  .profile .accont-tab .nav .nav-link {
    margin-right: 10px;
  }
}
.profile .accont-tab .nav .nav-link i {
  margin-right: 10px;
}
@media (max-width: 991px) {
  .profile .tab-content {
    margin-top: 25px;
  }
}
.profile .player-profile {
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
.profile .player-profile .player-card {
  padding: 30px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
}
@media (min-width: 576px) and (max-width: 991px) {
  .profile .player-profile .player-card {
    height: 100%;
  }
}
.profile .player-profile .part-pic {
  padding: 1px;
  background: #fff;
}
.profile .player-profile .part-pic img {
  width: 100%;
  border: 1px solid #4776e6;
}
.profile .player-profile .social-link {
  text-align: center;
  margin-top: 20px;
}
.profile .player-profile .social-link ul li {
  display: inline-block;
}
.profile .player-profile .social-link ul li a {
  display: inline-block;
  color: #fff;
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 6px;
  transition: all 0.3s ease-in;
  background: transparent;
}
.profile .player-profile .social-link ul li a:hover {
  background: #fff;
  border-color: transparent;
  color: #8e54e9;
}
.profile .player-profile .social-link ul li:last-child a {
  margin-right: 0;
}
.profile .player-profile .part-info {
  padding: 30px 40px;
}
@media (max-width: 479px) {
  .profile .player-profile .part-info {
    padding: 25px 30px;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .profile .player-profile .part-info {
    padding: 30px;
  }
}
.profile .player-profile .part-info .player-name {
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #49596d;
  margin-bottom: 22px;
}
@media (max-width: 479px) {
  .profile .player-profile .part-info .player-name {
    margin-bottom: 4px;
    font-size: 20px;
  }
}
.profile .player-profile .part-info .player-name img {
  width: 36px;
  margin-top: -10px;
  margin-left: 10px;
}
.profile .player-profile .part-info ul li {
  display: flex;
  align-items: flex-start;
  padding: 11px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
@media (max-width: 479px) {
  .profile .player-profile .part-info ul li {
    display: block;
  }
}
.profile .player-profile .part-info ul li span {
  display: inline-block;
}
@media (max-width: 479px) {
  .profile .player-profile .part-info ul li span {
    display: block;
  }
}
.profile .player-profile .part-info ul li span.property {
  width: 180px;
  font-weight: 500;
}
@media (max-width: 479px) {
  .profile .player-profile .part-info ul li span.property {
    display: block;
    width: 100%;
    margin-bottom: 6px;
  }
}
.profile .player-profile .part-info ul li span.property i {
  font-size: 12px;
  top: -1px;
  position: relative;
  margin-right: 5px;
  color: #49596d;
  opacity: 0.6;
}
.profile .player-profile .part-info ul li:last-child {
  border-bottom: none;
}
@media (max-width: 479px) {
  .profile .edit-profile .btn-hyipox-2 {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}
@media (max-width: 479px) {
  .profile .edit-profile {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
}

/*==========================================
    payment info
==========================================*/
@media (max-width: 767px) {
  .payment-info .responsive {
    overflow-x: scroll;
    padding: 5px;
  }
}
.payment-info .table {
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  border: none;
}
@media (max-width: 767px) {
  .payment-info .table {
    width: 800px;
  }
}
.payment-info .table tbody tr td {
  border-top: none;
  border-left: none;
  padding: 13px 30px;
  color: #49596d;
  font-size: 15px;
}
.payment-info .table tbody tr td:first-child {
  font-weight: 600;
}
.payment-info .table tbody tr td:nth-child(3) {
  border-right: none;
  border-right: none;
  padding: 0 30px;
  width: 180px;
}
.payment-info .table tbody tr td input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  padding: 0 20px;
}
.payment-info .table tbody tr td a.payment-info-button {
  height: 30px;
  width: 100px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50px;
  position: relative;
  z-index: 2;
  display: inline-block;
  overflow: hidden;
  position: relative;
  top: 25px;
  transform: translateY(-50%);
  text-transform: capitalize;
}
.payment-info .table tbody tr:last-child td {
  border-bottom: none;
}
.payment-info .table:last-child {
  margin-bottom: 0;
}

/*==========================================
    privacy settings
==========================================*/
@media (max-width: 479px) {
  .privacy-settings .responsive, .account-settings .responsive {
    overflow-x: scroll;
    padding: 5px;
  }
}
.privacy-settings .table, .account-settings .table {
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  border: none;
}
@media (max-width: 479px) {
  .privacy-settings .table, .account-settings .table {
    width: 600px;
  }
}
.privacy-settings .table tbody tr td, .account-settings .table tbody tr td {
  border-top: none;
  border-left: none;
  padding: 13px 30px;
  color: #49596d;
  font-size: 15px;
}
.privacy-settings .table tbody tr td:first-child, .account-settings .table tbody tr td:first-child {
  font-weight: 600;
}
.privacy-settings .table tbody tr td:last-child, .account-settings .table tbody tr td:last-child {
  border-right: none;
  border-right: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 30px;
}
.privacy-settings .table tbody tr td input, .account-settings .table tbody tr td input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  padding: 0 20px;
}
.privacy-settings .table tbody tr td a.payment-info-button, .account-settings .table tbody tr td a.payment-info-button {
  height: 30px;
  width: 100px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  border-radius: 50px;
  position: relative;
  z-index: 2;
  display: inline-block;
  overflow: hidden;
}
.privacy-settings .table tbody tr:last-child td, .account-settings .table tbody tr:last-child td {
  border-bottom: none;
}
.privacy-settings .table:last-child, .account-settings .table:last-child {
  margin-bottom: 0;
}

/*==========================================
    profile bar
==========================================*/
.profile-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-bar span {
  display: block;
}
.profile-bar span.part-name {
  font-size: 18px;
  color: #fff;
  font-family: "Yantramanav", sans-serif;
  margin-right: 20px;
  font-style: italic;
}
.profile-bar span.part-pic {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #fff;
}
.profile-bar span.part-pic img {
  width: 100%;
}

.user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: inline-block;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
}
.user-menu ul li a {
  display: flex;
  padding: 0px 0px;
  font-size: 16px;
  color: #6253c0;
  font-family: "Yantramanav", sans-serif;
  padding: 5px 0px;
  transition: all 0.3s ease-in;
}
.user-menu ul li a svg {
  width: 17px;
  margin-right: 10px;
}
.user-menu ul li a:hover {
  color: #8e54e9;
}
.user-menu ul li:last-child {
  text-align: center;
}
.user-menu ul li:last-child a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  border-bottom: none;
  color: blue;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  color: #fff;
  padding: 6px 20px 8px;
  margin-top: 6px;
  border-radius: 5px;
}
.user-menu ul li:last-child a svg {
  margin-left: 2px;
}

.nav-profile {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.nav-profile:hover .user-menu {
  opacity: 1;
  visibility: visible;
}
.nav-profile .user-menu {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.cart {
  padding: 120px 0;
}
.cart .order-summary-image {
  height: 80px;
}
.cart .order-summary-image img {
  height: 100%;
}
.cart .all-orders {
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin-right: -50px;
}
.cart .single-order {
  padding: 15px 0;
  border: 1px dashed #ddd;
  border-bottom: none;
}
.cart .single-order:last-child {
  border-bottom: 1px dashed #ddd;
}
.cart .table-order {
  border: none;
  margin-bottom: 0;
}
.cart .table-order tbody tr td {
  border: none;
  vertical-align: middle;
}
.cart .table-order tbody tr td.col-amount {
  font-size: 20px;
  font-weight: 600;
  font-family: "Yantramanav", sans-serif;
  color: #6253c0;
}
.cart .table-order tbody tr td .tooltip-bottom {
  color: red;
}
.cart .table-order tbody tr td .order-summary-button-details {
  color: green;
}
.cart .order-summary-content .order-summary-content-header {
  font-size: 16px;
  font-family: "Yantramanav", sans-serif;
  font-weight: 600;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.cart .order-summary-content span {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
}
.cart .order-summary-content .text span {
  color: #4776e6;
}
.cart .cart-summary-area {
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 5px;
}
.cart .cart-summary-area .amount-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}
.cart .cart-summary-area .amount-wrap:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 0;
  width: 1px;
  background: #ddd;
  height: 80%;
  transform: rotate(14deg);
}
.cart .cart-summary-area .amount-wrap div {
  text-align: center;
  padding: 0;
}
.cart .cart-summary-area .amount-wrap div .title {
  font-size: 18px;
  font-weight: 600;
  color: #6253c0;
}
.cart .cart-summary-area .amount-wrap div .amount {
  font-size: 17px;
}
.cart .cart-summary-area .summary-area-title {
  color: #8e54e9;
  text-transform: capitalize;
  font-weight: 500;
  padding-left: 30px;
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  color: #fff;
  padding: 20px 0;
  text-align: center;
  border-radius: 0 0 10px 10px;
}
.cart .sub-total-amount .title,
.cart .discount-amount .title {
  font-family: "Yantramanav", sans-serif;
  color: #6253c0;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}
.cart .sub-total-amount,
.cart .discount-amount {
  margin-bottom: 15px;
  padding: 0 30px;
}
.cart .total-amount {
  background: linear-gradient(-19deg, #4776e6 10%, #8e54e9);
  padding: 15px 30px 0 30px;
  text-align: center;
  border-radius: 10px;
  padding: 23px 0 36px;
  position: relative;
}
.cart .total-amount .title {
  text-transform: uppercase;
  font-family: "Yantramanav", sans-serif;
  display: block;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}
.cart .total-amount .amount {
  font-size: 35px;
  font-weight: 700;
  display: block;
  text-align: center;
  color: #ffffff;
}
.cart .total-amount .payment-btn {
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 35px;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  border: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  outline: none;
  bottom: -20px;
}
.cart .total-amount .payment-btn span {
  color: #ffffff;
  margin-left: 5px;
}
.cart .cart-summary-area .card-area {
  padding: 45px 0 30px;
}
.cart .cart-summary-area .card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.cart .cart-summary-area .card-list a {
  width: 20%;
}

.cart-pallet {
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2px 20px;
  margin-right: 20px;
  position: relative;
  top: 8px;
}
.cart-pallet .part-icon {
  display: inline-block;
  float: left;
  position: relative;
  margin-right: 14px;
}
.cart-pallet .part-icon span {
  display: inline-block;
}
.cart-pallet .part-icon span.icon {
  color: #fff;
}
.cart-pallet .part-icon span.icon a {
  color: #fff;
}
.cart-pallet .part-icon span.icon svg {
  width: 26px;
}
.cart-pallet .part-icon span.number {
  width: 20px;
  height: 20px;
  background: linear-gradient(-19deg, #8e54e9 10%, #4776e6);
  color: #6253c0;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  left: 0px;
}
.cart-pallet .part-amount {
  display: inline-block;
}
.cart-pallet .part-amount span {
  display: block;
}
.cart-pallet .part-amount span.amount {
  font-size: 16px;
  color: #fff;
  margin-bottom: -5px;
}
.cart-pallet .part-amount span.text {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  position: relative;
  top: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-oitila .part-txt {
    padding: 100px 0;
  }
  .breadcrumb-oitila .part-txt h1 {
    font-size: 60px;
    margin-top: -6px;
    margin-bottom: 10px;
  }
  .breadcrumb-oitila .part-txt ul li {
    font-size: 16px;
  }

  .team-inner .single-box {
    padding: 30px 0;
  }
  .team-inner .single-box:after {
    top: -130px;
    left: -26px;
    width: 270px;
    height: 270px;
  }
  .team-inner .single-box .part-img {
    width: 160px;
    margin-bottom: 25px;
  }

  .contact .bg-tamim {
    padding: 40px 15px;
  }

  .blog-page .blog-sidebar .single-sidebar {
    padding: 25px;
  }
  .blog-page .blog-sidebar .single-sidebar .popular-news .single-post {
    padding: 20px;
  }
  .blog-page .blog-sidebar .single-sidebar .popular-news .single-post .part-img {
    width: 70px;
    margin-right: 10px;
  }

  .transaction-page .table-flex .table thead tr th {
    font-size: 15px;
  }
  .transaction-page .table-flex .table tbody tr th {
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th span {
    font-size: 15px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th .user img {
    width: 35px;
    margin-right: 15px;
  }
  .transaction-page .table-flex .table tbody tr td {
    font-size: 15px;
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr td img {
    width: 30px;
  }

  .calculation .part-txt h2 {
    font-size: 42px;
    line-height: 52px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-oitila {
    padding-top: 0;
  }
  .breadcrumb-oitila .part-txt {
    padding: 80px 0;
  }
  .breadcrumb-oitila .part-txt h1 {
    font-size: 50px;
    margin-top: -6px;
    margin-bottom: 10px;
  }
  .breadcrumb-oitila .part-txt ul li {
    font-size: 16px;
  }
  .breadcrumb-oitila .part-img img {
    width: 80%;
  }

  .about.about-inner {
    padding-top: 60px;
  }

  .team-inner {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .team-inner .single-box {
    width: 270px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .contact {
    padding: 60px 0;
  }
  .contact .bg-tamim {
    padding: 30px;
    padding-bottom: 4px;
  }
  .contact .bg-tamim .part-form {
    margin-bottom: 30px;
  }
  .contact .bg-tamim .part-form form input {
    height: 45px;
    font-size: 14px;
    padding: 0 15px;
  }
  .contact .bg-tamim .part-form form textarea {
    height: 110px;
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 13px;
  }
  .contact .bg-tamim .part-form form button {
    height: 45px;
    line-height: 41px;
    padding: 0 25px;
  }
  .contact .bg-tamim .part-address .single-address {
    margin-bottom: 21px;
  }

  .blog-page {
    padding: 60px 0;
  }
  .blog-page .blog nav {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .blog-details-page .comment-form {
    margin-bottom: 30px;
  }
  .blog-details-page .comment-form form textarea {
    height: 120px;
  }

  .map {
    margin-top: -150px;
  }
  .map iframe {
    height: 400px;
  }

  .error {
    padding: 60px 0;
  }
  .error .part-text h4 {
    font-size: 24px;
  }

  .faq {
    padding: 60px 0 41px;
  }
  .faq .faq-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .faq .faq-sidebar .nav .nav-link {
    height: 55px;
    line-height: 55px;
    padding-left: 25px;
  }
  .faq .faq-content {
    margin-left: 0;
  }

  .login {
    padding: 60px 0;
  }

  .register {
    padding: 60px 0;
  }
  .register .reg-body {
    padding: 20px 40px 40px;
  }
  .register .reg-body form .sub-title {
    margin-top: 13px;
    margin-bottom: 19px;
  }
  .register .reg-body form input[type=text], .register .reg-body form [type=email], .register .reg-body form [type=password] {
    height: 50px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  .register .reg-body form .term-condition {
    margin-top: 18px;
  }
  .register .reg-body form .term-condition .title {
    margin-bottom: 12px;
  }
  .register .reg-body form .btn-form {
    float: left;
  }

  .transaction-page {
    padding: 60px 0;
  }
  .transaction-page .table-flex {
    overflow-x: scroll;
    padding: 0;
  }
  .transaction-page .table-flex .table {
    width: 798px;
  }
  .transaction-page .table-flex .table thead tr th {
    font-size: 15px;
  }
  .transaction-page .table-flex .table tbody tr th {
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th span {
    font-size: 15px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th .user img {
    width: 35px;
    margin-right: 15px;
  }
  .transaction-page .table-flex .table tbody tr td {
    font-size: 15px;
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr td img {
    width: 30px;
  }

  .calculation {
    padding: 60px 0;
  }
  .calculation .part-txt {
    margin-bottom: 50px;
  }
  .calculation .part-txt h2 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 12px;
  }
  .calculation .part-txt ul li {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 6px;
  }
  .calculation .part-txt ul li i {
    margin-right: 15px;
  }
  .calculation .part-txt .btn-hyipox-2 {
    margin-top: 17px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-oitila {
    padding-top: 0;
  }
  .breadcrumb-oitila .part-txt {
    padding: 60px 0;
  }
  .breadcrumb-oitila .part-txt h1 {
    font-size: 45px;
    margin-top: -6px;
    margin-bottom: 10px;
  }
  .breadcrumb-oitila .part-txt ul li {
    font-size: 16px;
  }
  .breadcrumb-oitila .part-img img {
    width: 70%;
  }

  .about.about-inner {
    padding-top: 60px;
  }

  .team-inner {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .team-inner .single-box {
    margin-bottom: 30px;
  }

  .contact {
    padding: 60px 0;
  }
  .contact .bg-tamim {
    padding: 30px;
    padding-bottom: 4px;
  }
  .contact .bg-tamim .part-form {
    margin-bottom: 30px;
  }
  .contact .bg-tamim .part-form form input {
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
  }
  .contact .bg-tamim .part-form form textarea {
    height: 110px;
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 13px;
  }
  .contact .bg-tamim .part-form form button {
    height: 40px;
    line-height: 36px;
    padding: 0 25px;
  }
  .contact .bg-tamim .part-address .single-address {
    margin-bottom: 21px;
  }

  .blog-page {
    padding: 60px 0;
  }
  .blog-page .blog nav {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .blog-page .blog-sidebar {
    width: 360px;
    margin: 0 auto;
  }

  .blog-details-page .blog .part-text a.title {
    font-size: 25px;
    margin-top: 19px;
  }
  .blog-details-page .comment-area .single-comment.reply {
    padding-left: 20px;
  }
  .blog-details-page .comment-form {
    margin-bottom: 30px;
  }
  .blog-details-page .comment-form form textarea {
    height: 120px;
  }

  .map {
    margin-top: -100px;
  }
  .map iframe {
    height: 300px;
  }

  .error {
    padding: 60px 0;
  }
  .error .part-text h4 {
    font-size: 24px;
    line-height: 34px;
  }

  .faq {
    padding: 60px 0 41px;
  }
  .faq .faq-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .faq .faq-sidebar .nav .nav-link {
    height: 55px;
    line-height: 55px;
  }
  .faq .faq-content {
    margin-left: 0;
  }

  .login {
    padding: 60px 0;
  }

  .register {
    padding: 60px 0;
  }
  .register .reg-body {
    padding: 20px 40px 40px;
  }
  .register .reg-body form .sub-title {
    margin-top: 13px;
    margin-bottom: 19px;
  }
  .register .reg-body form input[type=text], .register .reg-body form [type=email], .register .reg-body form [type=password] {
    height: 50px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  .register .reg-body form .term-condition {
    margin-top: 18px;
  }
  .register .reg-body form .term-condition .title {
    margin-bottom: 12px;
  }
  .register .reg-body form .btn-form {
    float: left;
  }

  .transaction-page {
    padding: 60px 0;
  }
  .transaction-page .table-flex {
    overflow-x: scroll;
    padding: 0;
  }
  .transaction-page .table-flex .table {
    width: 798px;
  }
  .transaction-page .table-flex .table thead tr th {
    font-size: 15px;
  }
  .transaction-page .table-flex .table tbody tr th {
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th span {
    font-size: 15px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th .user img {
    width: 35px;
    margin-right: 15px;
  }
  .transaction-page .table-flex .table tbody tr td {
    font-size: 15px;
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr td img {
    width: 30px;
  }

  .calculation {
    padding: 60px 0;
  }
  .calculation .part-txt {
    margin-bottom: 50px;
  }
  .calculation .part-txt h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 12px;
  }
  .calculation .part-txt ul li {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 6px;
  }
  .calculation .part-txt ul li i {
    margin-right: 15px;
  }
  .calculation .part-txt .btn-hyipox-2 {
    height: 40px;
    line-height: 40px;
    margin-top: 17px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .breadcrumb-oitila {
    padding-top: 0;
  }
  .breadcrumb-oitila .col-8 {
    flex: 100%;
    max-width: 100%;
  }
  .breadcrumb-oitila .col-8 .part-txt {
    padding: 50px 0;
  }
  .breadcrumb-oitila .col-8 .part-txt h1 {
    font-size: 40px;
    margin-top: -6px;
    margin-bottom: 10px;
  }
  .breadcrumb-oitila .col-8 .part-txt ul li {
    font-size: 16px;
  }
  .breadcrumb-oitila .part-img {
    display: none;
  }

  .about.about-inner {
    padding-top: 60px;
  }

  .team-inner {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .team-inner .single-box {
    width: 270px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .contact {
    padding: 60px 0;
  }
  .contact .bg-tamim {
    padding: 30px;
    padding-bottom: 4px;
  }
  .contact .bg-tamim .part-form {
    margin-bottom: 30px;
  }
  .contact .bg-tamim .part-form form input {
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
  }
  .contact .bg-tamim .part-form form textarea {
    height: 110px;
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 13px;
  }
  .contact .bg-tamim .part-form form button {
    height: 40px;
    line-height: 36px;
    padding: 0 25px;
  }
  .contact .bg-tamim .part-address .single-address {
    margin-bottom: 21px;
  }
  .contact .bg-tamim .part-address .single-address h4 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 7px;
  }
  .contact .bg-tamim .part-address .single-address p {
    font-size: 15px;
    line-height: 26px;
    font-weight: 300;
  }

  .blog-page {
    padding: 60px 0;
  }
  .blog-page .blog nav {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .blog-page .blog-sidebar {
    width: 360px;
    margin: 0 auto;
  }

  .blog-details-page .blog .part-text a.title {
    font-size: 24px;
    margin-top: 19px;
  }
  .blog-details-page .comment-area .single-comment {
    display: block;
    padding-right: 0;
  }
  .blog-details-page .comment-area .single-comment .part-img {
    margin-bottom: 20px;
  }
  .blog-details-page .comment-form {
    margin-bottom: 30px;
  }
  .blog-details-page .comment-form form input {
    height: 40px;
    padding: 0 20px;
    font-size: 15px;
  }
  .blog-details-page .comment-form form textarea {
    height: 120px;
    padding: 12px 20px;
    font-size: 15px;
  }

  .map {
    margin-top: -100px;
  }
  .map iframe {
    height: 300px;
  }

  .error {
    padding: 60px 0;
  }
  .error .part-img {
    text-align: center;
  }
  .error .part-img img {
    width: 80%;
  }
  .error .part-text h4 {
    font-size: 24px;
  }

  .faq {
    padding: 60px 0 41px;
  }
  .faq .faq-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .faq .faq-sidebar .nav .nav-link {
    height: 50px;
    line-height: 50px;
  }
  .faq .faq-content {
    margin-left: 0;
  }

  .login {
    padding: 60px 0;
  }

  .register {
    padding: 60px 0;
  }
  .register .reg-body {
    padding: 10px 30px 30px;
  }
  .register .reg-body form .sub-title {
    margin-top: 13px;
    margin-bottom: 19px;
  }
  .register .reg-body form input[type=text], .register .reg-body form [type=email], .register .reg-body form [type=password] {
    height: 40px;
    padding: 0 20px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .register .reg-body form .term-condition {
    margin-top: 18px;
  }
  .register .reg-body form .term-condition .title {
    margin-bottom: 12px;
  }
  .register .reg-body form .term-condition p {
    border: 0px;
    border-radius: 0;
    line-height: 28px;
    padding: 0;
    margin-bottom: 24px;
  }
  .register .reg-body form .btn-form {
    float: left;
  }

  .transaction-page {
    padding: 60px 0;
  }
  .transaction-page .table-flex {
    overflow-x: scroll;
    padding: 0;
  }
  .transaction-page .table-flex .table {
    width: 798px;
  }
  .transaction-page .table-flex .table thead tr th {
    font-size: 15px;
  }
  .transaction-page .table-flex .table tbody tr th {
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th span {
    font-size: 15px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th .user img {
    width: 35px;
    margin-right: 15px;
  }
  .transaction-page .table-flex .table tbody tr td {
    font-size: 15px;
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr td img {
    width: 30px;
  }

  .calculation {
    padding: 60px 0;
  }
  .calculation .part-txt {
    margin-bottom: 50px;
  }
  .calculation .part-txt h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 12px;
  }
  .calculation .part-txt ul li {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 6px;
  }
  .calculation .part-txt ul li i {
    margin-right: 15px;
  }
  .calculation .part-txt .btn-hyipox-2 {
    height: 40px;
    line-height: 40px;
    margin-top: 17px;
  }
  .calculation .profit-calculator {
    width: 380px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .breadcrumb-oitila {
    padding-top: 0;
  }
  .breadcrumb-oitila .col-8 {
    flex: 100%;
    max-width: 100%;
  }
  .breadcrumb-oitila .col-8 .part-txt {
    padding: 50px 0;
  }
  .breadcrumb-oitila .col-8 .part-txt h1 {
    font-size: 40px;
    margin-top: -6px;
    margin-bottom: 10px;
  }
  .breadcrumb-oitila .col-8 .part-txt ul li {
    font-size: 16px;
  }
  .breadcrumb-oitila .part-img {
    display: none;
  }

  .about.about-inner {
    padding-top: 60px;
  }

  .team-inner {
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .team-inner .single-box {
    width: 270px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .contact {
    padding: 60px 0;
  }
  .contact .bg-tamim {
    padding: 30px;
    padding-bottom: 4px;
  }
  .contact .bg-tamim .part-form {
    margin-bottom: 30px;
  }
  .contact .bg-tamim .part-form form input {
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
  }
  .contact .bg-tamim .part-form form textarea {
    height: 110px;
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 13px;
  }
  .contact .bg-tamim .part-form form button {
    height: 40px;
    line-height: 36px;
    padding: 0 25px;
  }
  .contact .bg-tamim .part-address .single-address {
    margin-bottom: 21px;
  }
  .contact .bg-tamim .part-address .single-address h4 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 7px;
  }
  .contact .bg-tamim .part-address .single-address p {
    font-size: 15px;
    line-height: 26px;
    font-weight: 300;
  }

  .blog-page {
    padding: 60px 0;
  }
  .blog-page .blog nav {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .blog-page .blog nav ul li {
    width: 35px;
  }
  .blog-page .blog nav ul li a {
    height: 35px;
    line-height: 35px;
  }
  .blog-page .blog-sidebar .single-sidebar {
    padding: 20px;
  }
  .blog-page .blog-sidebar .single-sidebar .title {
    font-size: 18px;
    font-weight: 700;
  }
  .blog-page .blog-sidebar .single-sidebar .popular-news .single-post {
    padding: 15px;
  }
  .blog-page .blog-sidebar .single-sidebar .popular-news .single-post .part-img {
    width: 65px;
    margin-right: 10px;
  }
  .blog-page .blog-sidebar .single-sidebar .tags a {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 13px;
  }

  .blog-details-page .blog {
    padding: 20px;
  }
  .blog-details-page .blog .part-img .post-date {
    width: 55px;
    height: 55px;
  }
  .blog-details-page .blog .part-img .post-date span.date {
    font-size: 20px;
  }
  .blog-details-page .blog .part-img .post-date span.month {
    font-size: 12px;
  }
  .blog-details-page .blog .part-text a.title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 19px;
  }
  .blog-details-page .blog .part-text .meta-info {
    margin-bottom: 13px;
  }
  .blog-details-page .blog .part-text p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 22px;
  }
  .blog-details-page .blog .part-text p.marked {
    padding: 20px;
  }
  .blog-details-page .comment-area {
    padding: 20px;
  }
  .blog-details-page .comment-area .single-comment {
    display: block;
    padding-right: 0;
  }
  .blog-details-page .comment-area .single-comment.reply {
    padding-left: 0;
  }
  .blog-details-page .comment-area .single-comment .part-img {
    margin-bottom: 20px;
  }
  .blog-details-page .comment-form {
    padding: 20px;
    margin-bottom: 30px;
  }
  .blog-details-page .comment-form form input {
    height: 40px;
    padding: 0 20px;
    font-size: 15px;
  }
  .blog-details-page .comment-form form textarea {
    height: 120px;
    padding: 12px 20px;
    font-size: 15px;
  }

  .map {
    margin-top: -100px;
  }
  .map iframe {
    height: 300px;
  }

  .error {
    padding: 60px 0;
  }
  .error .part-text h4 {
    font-size: 20px;
  }

  .faq {
    padding: 60px 0 41px;
  }
  .faq .faq-sidebar {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .faq .faq-sidebar .search-widget form input {
    height: 45px;
    padding: 0 20px;
  }
  .faq .faq-sidebar .search-widget form button {
    height: 45px;
    width: 45px;
  }
  .faq .faq-sidebar .nav .nav-link {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
  }
  .faq .faq-content {
    margin-left: 0;
  }
  .faq .faq-content .tab-pane .single-faq {
    padding: 24px 30px 6px;
  }
  .faq .faq-content .tab-pane .single-faq h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .login {
    padding: 60px 0;
  }
  .login .form-area .login-form {
    padding: 30px 20px;
  }
  .login .form-area .login-form form .form-group {
    margin-bottom: 14px;
  }
  .login .form-area .login-form form .form-group.form-check {
    margin-top: 24px;
  }
  .login .form-area .login-form form .form-group.form-check label {
    padding-left: 0;
  }
  .login .form-area .login-form form .form-group.form-check .btn-form {
    padding: 0 20px;
  }
  .login .form-area .login-form .login-by-social a {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
  .login .form-area .login-form .login-by-social a i {
    line-height: 35px;
    width: 35px;
    font-size: 12px;
    font-weight: 300;
  }
  .login .form-area .login-form .login-by-social a.login-fb {
    margin-bottom: 20px;
  }

  .register {
    padding: 60px 0;
  }
  .register .reg-body {
    padding: 10px 30px 30px;
  }
  .register .reg-body form .sub-title {
    font-size: 22px;
    margin-top: 13px;
    margin-bottom: 19px;
  }
  .register .reg-body form input[type=text], .register .reg-body form [type=email], .register .reg-body form [type=password] {
    height: 40px;
    padding: 0 20px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .register .reg-body form .term-condition {
    margin-top: 18px;
  }
  .register .reg-body form .term-condition .title {
    font-size: 22px;
    margin-bottom: 12px;
  }
  .register .reg-body form .term-condition p {
    border: 0px;
    border-radius: 0;
    line-height: 28px;
    padding: 0;
    margin-bottom: 19px;
  }

  .transaction-page {
    padding: 60px 0;
  }
  .transaction-page .table-flex {
    overflow-x: scroll;
    padding: 0;
  }
  .transaction-page .table-flex .table {
    width: 798px;
  }
  .transaction-page .table-flex .table thead tr th {
    font-size: 15px;
  }
  .transaction-page .table-flex .table tbody tr th {
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th span {
    font-size: 15px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr th .user img {
    width: 35px;
    margin-right: 15px;
  }
  .transaction-page .table-flex .table tbody tr td {
    font-size: 15px;
    height: 60px;
    line-height: 60px;
  }
  .transaction-page .table-flex .table tbody tr td img {
    width: 30px;
  }

  .calculation {
    padding: 60px 0;
  }
  .calculation .part-txt {
    margin-bottom: 50px;
  }
  .calculation .part-txt h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 12px;
  }
  .calculation .part-txt p {
    font-size: 15px;
    line-height: 25px;
  }
  .calculation .part-txt ul li {
    font-size: 15px;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 6px;
  }
  .calculation .part-txt ul li i {
    margin-right: 15px;
  }
  .calculation .part-txt .btn-hyipox-2 {
    height: 40px;
    line-height: 40px;
    margin-top: 17px;
  }
  .calculation .profit-calculator .calc-header h3.title {
    font-size: 20px;
    line-height: 30px;
  }
  .calculation .profit-calculator .calc-body {
    padding: 25px 15px;
  }
  .calculation .profit-calculator .part-plan .dropdown .dropdown-item {
    font-size: 16px;
  }
  .calculation .profit-calculator .part-plan .dropdown a.dropdown-toggle {
    font-size: 16px;
  }
  .calculation .profit-calculator .part-amount form input {
    font-size: 16px;
  }
  .calculation .profit-calculator .part-amount form .dropdown-item {
    font-size: 16px;
  }
  .calculation .profit-calculator .part-result ul li .icon {
    display: none;
  }
}